import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import { SchoolYears } from '../../types/schoolyears';

interface Props {
  schoolYears: SchoolYears[] | undefined;
  selectedSchoolYear?: string;
  handleChangeSchoolYear: (schoolYearId?: string) => void;
}

const SchoolYearsDropdown = ({ schoolYears, selectedSchoolYear, handleChangeSchoolYear }: Props) => {
  const options = [{ label: 'All', value: 'All' }];
  const [selected, setSelectedValue] = useState<{ label: string; value: string }>();

  const setDropDownData = () => {
    if (schoolYears) {
      for (const schoolYear of schoolYears) {
        if (schoolYear.display) {
          let schoolYearLabel = schoolYear.name;
          if (schoolYear.current) {
            schoolYearLabel += ' (Current Year)';
          }
          options.push({ label: schoolYearLabel, value: schoolYear.id });
        }
      }
    }
  };

  setDropDownData();

  const handleChange = (option: SingleValue<{ label: string; value: string }>) => {
    handleChangeSchoolYear(option?.value);
  };

  useEffect(() => {
    const selectedOption = options.find((obj) => obj.value === selectedSchoolYear);
    setSelectedValue(selectedOption !== undefined ? selectedOption : options[0]);
  }, [selectedSchoolYear]);

  return (
    <>
      <Row>
        <Col>
          <h6>Select the Date Range:</h6>
        </Col>
      </Row>
      <Row>
        <Col xs="9" sm="10" md="10" lg="7">
          <Select options={options} onChange={handleChange} value={selected} />
        </Col>
      </Row>
    </>
  );
};

export default SchoolYearsDropdown;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../styles/archiveui.module.css';
import logo from './scribonline_blue.png';
import birdHead from './bird-logo-head.png';

interface sideBarProps {
  isOpen: boolean;
  toggle: () => void;
}

const Navigation = ({ isOpen, toggle }: sideBarProps) => {
  const location = useLocation();
  let pageTitle = '';
  if (location.pathname === '/insights') {
    pageTitle = 'Data & Insights';
  } else if (location.pathname === '/requests') {
    pageTitle = 'Transfer Requests (outbound)';
  } else if (location.pathname === '/users') {
    pageTitle = 'Users';
  } else if (location.pathname === '/importusers') {
    pageTitle = 'Import Users';
  } else if (location.pathname === '/roles') {
    pageTitle = 'Roles';
  } else if (location.pathname === '/meta') {
    pageTitle = 'Manage Documents';
  } else if (location.pathname === '/batch') {
    pageTitle = 'Manage Batches';
  } else if (location.pathname === '/accountlist') {
    pageTitle = 'Manage Accounts';
  } else if (location.pathname === '/auditlog') {
    pageTitle = 'Audit Log';
  } else if (location.pathname === '/') {
    pageTitle = 'Users';
  }
  return (
    <div>
      <div className={styles.mainNav} style={{ maxHeight: '56px', padding: 'none' }}>
        <img src={birdHead} style={{ height: '45px', top: 0, left: 0, transform: 'scaleX(-1)' }} alt="" />
        <img src={logo} style={{ height: '56px', top: 0, left: 0, display: isOpen ? 'flex' : 'none' }} alt="" />
        <div style={{ textAlign: 'right', width: '100%', margin: '0 4em' }}>
          <h5>{pageTitle}</h5>
        </div>
      </div>
    </div>
  );
};

export default Navigation;

/* eslint-disable react/jsx-key */
import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';

interface EnrollmentSettingsTableProps {
  enrollmentSettings: any[];
  setEnrollmentSettingsState: (setting: string, value: boolean) => void;
}

const EnrollmentSettingsTable = ({ enrollmentSettings, setEnrollmentSettingsState }: EnrollmentSettingsTableProps) => {
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Enrollment Setting',
        accessor: 'enrollmentSetting', // accessor is the "key" in the data
      },
      {
        Header: 'Show',
        accessor: 'show',
      },
      {
        Header: 'Toggle',
        Cell: (props: any) => {
          return <tr>{props.actions}</tr>;
        },
      },
    ],
    [],
  );

  const data = React.useMemo(() => {
    return enrollmentSettings ?? [{}];
  }, [enrollmentSettings]);

  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, rows } = useTable(
    { columns, data: data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
  );

  return (
    <div>
      <Table size="sm" bordered striped hover responsive {...getTableProps()} style={{}}>
        <thead className={styles.tableHeader}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                if (column.Header === 'Display Setting') {
                  return (
                    <th className={styles.IdHeader} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  );
                } else {
                  return (
                    <th className={styles.th} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  );
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.Header === 'Show') {
                    if (cell.value === true) {
                      return (
                        <td {...cell.getCellProps()} style={{}}>
                          true
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} style={{}}>
                          false
                        </td>
                      );
                    }
                  } else if (cell.column.Header === 'Toggle') {
                    return (
                      <td {...cell.getCellProps()} style={{}}>
                        <Button
                          onClick={() => {
                            setEnrollmentSettingsState(cell.row.values.displaySetting, !cell.row.values.show);
                          }}
                        >
                          Toggle
                        </Button>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()} style={{}}>
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default EnrollmentSettingsTable;

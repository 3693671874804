import React, { Component, ErrorInfo, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  componentDidMount() {
    this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState(ErrorBoundary.getDerivedStateFromError(error));
  }

  reloadPage() {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
        <main role="main" className="errorContainer container">
          <div className="alert alert-secondary text-center">
            <div className="container">
              <h1>{'Something went wrong...'}</h1>
              <br />
              <span style={{ color: 'white' }}>
                <a className="btn btn-primary" onClick={this.reloadPage}>
                  Reload
                </a>
              </span>
            </div>
          </div>
        </main>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { Permission } from '../../types/role';
import styles from '../../styles/archiveui.module.css';

interface PermissionModalProps {
  modal: boolean;
  toggle: () => void;
  newPermission: boolean;
  permission: Permission | undefined;
}

const PermissionModal = ({ modal, toggle, newPermission, permission }: PermissionModalProps) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{newPermission ? 'Add Permission' : 'Edit Permission'}</ModalHeader>
        <ModalBody>
          <Form>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input value={permission?.name} />
                  <FormFeedback></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input value={permission?.description} type="textarea" />
                  <FormFeedback></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className={styles.scrbBtnBlue} onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button className={styles.scrbBtnOrange} onClick={toggle}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PermissionModal;

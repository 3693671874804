import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid';

interface ToastProps {
  toastType?: string;
  toastText?: string;
}

export const toastSyncError = async (message: string) => {
  toast.error(message);
};

export const toastSuccess = async (message: string) => {
  toast.success(message);
};

export const toastError = async (promise: Promise<any>, message: string) => {
  return promise.catch(() => {
    toast.error(message);
  });
};

const Toast = ({ toastType, toastText }: ToastProps) => {
  return (
    <div>
      <ToastContainer theme="colored" position="bottom-right" />
    </div>
  );
};

export default Toast;

import React, { useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { User } from '../../../types/user';
import { Card, Table, Input, Button } from 'reactstrap';
import styles from '../../../styles/archiveui.module.css';
import SearchBar from '../userlisttable/SearchBar';
import { AiFillEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

interface UserTableProps {
  users: User[];
  tenantid: string;
}

const UserTable = ({ users, tenantid }: UserTableProps) => {
  const history = useHistory();
  const [searchText, updateSearchText] = useState<string>('');
  const data = users;

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Last Name',
        accessor: 'familyName', // accessor is the "key" in the data
      },
      {
        Header: 'First Name',
        accessor: 'givenName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/display-name
        Cell: () => (
          <td className={styles.actionsCell}>
            <AiFillEdit id="edit" color="#212529" />
          </td>
        ),
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 10 } }, useSortBy, usePagination);

  const onRowClick = (cell: User | any) => {
    if (cell.column.Header === 'Actions') {
      history.push(`/accountuserdetail/${tenantid}/${cell.row.original.id}`);
    }
  };

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <div>
      <div className={styles.userTableContainer}>
        <SearchBar tenantId={tenantid} searchText={searchText} updateSearchText={updateSearchText} />
        <Card className={styles.tableContainer}>
          <Table striped hover size="sm" id="auditTable" responsive style={{ backgroundColor: 'white' }} {...getTableProps()}>
            <thead className={styles.tableHeader}>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.Header === 'Last Name') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    } else if (column.Header === 'Actions') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page
                .filter((row) => {
                  if (searchText == '') {
                    return row;
                  } else if (row.original.familyName.toLowerCase().includes(searchText)) {
                    return row;
                  } else if (row.original.givenName.toLocaleLowerCase().includes(searchText)) {
                    return row;
                  } else if (row.original.id.includes(searchText)) {
                    return row;
                  } else if (row.original.email.toLowerCase().includes(searchText)) {
                    return row;
                  } else if (row.original.phone.includes(searchText)) {
                    return row;
                  }
                })
                .map((row, i) => {
                  prepareRow(row);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        if (cell.column.Header === 'Last Name') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td
                              style={{ paddingLeft: '1.25em' }}
                              onClick={() => {
                                onRowClick(cell);
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        } else if (cell.column.Header === 'Actions') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td
                              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
                              onClick={() => {
                                onRowClick(cell);
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        } else {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td
                              onClick={() => {
                                onRowClick(cell);
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card>
      </div>
      <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
        <div style={{ marginRight: '.5em' }}>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Button>{' '}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </Button>{' '}
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </Button>{' '}
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Button>{' '}
        </div>
        <div style={{ marginRight: '.5em' }}>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
        <Input
          style={{ maxWidth: '10em' }}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};

export default UserTable;

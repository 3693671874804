import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

const render = async () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

// if (process.env.NODE_ENV === 'development') {
//   import('./mocks/server').then((setup) => {
//     setup.setupMSW(process.env.REACT_APP_SANDBOX_ENV).then(() => {
//       render();
//     });
//   });
// } else {
render();
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

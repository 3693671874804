import React, { useEffect, useState } from 'react';
import styles from '../../styles/archiveui.module.css';
import AccountTable from '../accountlisttable/accounttable';
import { getAccounts } from '../../api/accounts';
import { Loading } from '../../Loading';
import { getUserProfile } from '../../api/userprofile';
import { Account } from '../../types/account';

const AccountsList = (): JSX.Element => {
  const [accounts, updateAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const setAccounts = async () => {
      const accounts = await getAccounts();
      updateAccounts(accounts);
      setLoading(false);

      const profile = await getUserProfile();
      console.log('got profile', profile);
    };

    setAccounts();
  }, []);

  return (
    <div className={styles.customLogContainer}>
      {loading && <Loading />}
      {!loading && <AccountTable accounts={accounts} />}
    </div>
  );
};

export default AccountsList;

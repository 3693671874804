import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import SchoolDistrictDropdown from './schooldistrictdropdown';
import SchoolYearsDropdown from './schoolyearsdropdown';
import { DistrictConfigSettings, UserConfigSettings } from '../../types/configsettings';
import ConfigModal from '../configmodal';
import { UserContext } from '../../contexts/UserContext';

interface Props {
  configSettings: UserConfigSettings;
  districtSettings: DistrictConfigSettings;
  selectedSchoolYearId: string;
  handleChangeYear: (schoolYearValue?: string) => void;
  selectedSchoolDistrictId: string;
  handleChangeSchoolDistrict?: (values: any) => void;
}

const DataView = ({
  configSettings,
  districtSettings,
  selectedSchoolYearId,
  handleChangeYear,
  selectedSchoolDistrictId,
  handleChangeSchoolDistrict,
}: Props) => {
  const [userConfigSettings, setUserConfigSettings] = useState(configSettings);
  const [districtConfigSettings, setDistrictConfigSettings] = useState(districtSettings);
  const [modal, setModal] = React.useState(false);
  const userProfile = React.useContext(UserContext);
  const userData = userProfile.user;

  // Toggle for Modal
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    setUserConfigSettings(configSettings);
    setDistrictConfigSettings(districtSettings);
  }, [configSettings, districtSettings]);

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col xs="12" sm="6" md="7">
              <h5>Data View Configuration:</h5>
            </Col>
            <Col xs="12" sm="6" md="5">
              <Button onClick={toggleModal}>Configuration</Button>
            </Col>
          </Row>
          <SchoolDistrictDropdown
            scope={userData.scope.name}
            scopeId={userData.scope.value}
            selectedDistrictSchoolId={selectedSchoolDistrictId}
            handleDropdownOnChange={handleChangeSchoolDistrict}
          />
          <SchoolYearsDropdown
            schoolYears={districtConfigSettings ? districtConfigSettings.schoolYearsSettings : []}
            selectedSchoolYear={selectedSchoolYearId}
            handleChangeSchoolYear={handleChangeYear}
          />
        </CardBody>
      </Card>
      <ConfigModal
        userSettings={userConfigSettings}
        districtSettings={districtSettings}
        modalState={modal}
        setModalState={toggleModal}
        setUpdatedConfigSettings={setUserConfigSettings}
      />
    </div>
  );
};

export default DataView;

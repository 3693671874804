import React, { ChangeEvent, KeyboardEvent } from 'react';
import { ListGroupItem, Input, CustomInput, Row, Col, Button } from 'reactstrap';
import { BsFillTrashFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import styles from '../../styles/archiveui.module.css';

interface AccountContactProps {
  name: string;
  email: string;
  role: string;
  phone: string;
  indexOfContact: number;
  isPrimary: boolean;
  newAccount: boolean;
  updateFunction: (targetName: string, event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>, index: number) => void;
  removeContactHandler(index: number): any;
}

const AccountContact: React.FC<AccountContactProps> = (props: AccountContactProps) => {
  return (
    <ListGroupItem className={styles.accountContactContainer}>
      <Row form>
        <Col>
          <Input
            name="name"
            value={props.name}
            placeholder="Contact Name"
            onChange={(e) => {
              props.updateFunction('name', e, props.indexOfContact);
            }}
            required
          />
        </Col>
        <Col>
          <Input
            name="email"
            value={props.email}
            placeholder="Contact Email"
            onChange={(e) => {
              props.updateFunction('email', e, props.indexOfContact);
            }}
            required
          />
        </Col>
        <Col>
          <PhoneInput
            country="us"
            preferredCountries={['us']}
            value={props.phone}
            placeholder="Contact Phone"
            inputStyle={{ width: '100%' }}
            onKeyDown={(event) => {
              props.updateFunction('phone', event, props.indexOfContact);
            }}
          />
        </Col>
        <Col md={2}>
          <CustomInput
            label="Primary Contant"
            name="isPrimary"
            type="checkbox"
            id={`isPrimary${props.indexOfContact}`}
            checked={props.isPrimary}
            onChange={(e) => {
              props.updateFunction('isPrimary', e, props.indexOfContact);
            }}
          />
        </Col>
        <Button
          color="danger"
          onClick={() => {
            props.removeContactHandler(props.indexOfContact);
          }}
        >
          <BsFillTrashFill />
        </Button>
      </Row>
    </ListGroupItem>
  );
};

export default AccountContact;

import fetch from 'node-fetch';
import { DistrictConfigSettings, UserConfigSettings } from '../types/configsettings';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getUserConfigSettings = async (userId: string): Promise<UserConfigSettings> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/configsettings/user/${userId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<UserConfigSettings>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const saveUserConfigSettings = async (userId: string, configSettings: UserConfigSettings, newConfigSettings: boolean): Promise<number> => {
  const method = newConfigSettings ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(configSettings),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/configsettings/user/${userId}`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getDistrictConfigSettings = async (districtId: string): Promise<DistrictConfigSettings> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/configsettings/district/${districtId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<DistrictConfigSettings>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const saveDistrictConfigSettings = async (districtId: string, configSettings: DistrictConfigSettings, newConfigSettings: boolean): Promise<number> => {
  const method = newConfigSettings ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(configSettings),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/configsettings/district/${districtId}`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

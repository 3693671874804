import React, { useContext, useEffect, useState } from 'react';
import { Column, HeaderGroup, useSortBy, useTable } from 'react-table';
import { Button, Card, CardBody, CustomInput, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { parseCSVToUser } from '.';
import { createUsersForTenant } from '../../api/users';
import { User } from '../../types/user';
import { toast } from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';
import { useHistory } from 'react-router';
import AccessControl from '../userprofile/accesscontrol';
import styles from '../../styles/archiveui.module.css';

interface ImportUser extends Partial<User> {
  selectedForImport?: boolean;
  alreadyExists?: boolean;
}

interface ImportTypesProps {
  tenantId: string;
}

const UserImport = ({ tenantId }: ImportTypesProps): JSX.Element => {
  const { permissions } = useContext(UserContext);
  const history = useHistory();
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [usersToImport, setUsersToImport] = useState<ImportUser[]>([]);
  let userAccessForPage: any;
  if (permissions[tenantId] != undefined) {
    userAccessForPage = permissions[tenantId]['users'].actions.create;
  }

  useEffect(() => {
    if (userAccessForPage === 'DENY' || userAccessForPage === 'NONE' || permissions === undefined) {
      toast.error('Not Authorized');
      history.push('/notauthorized');
    }
  }, []);

  const toggleImportModal = () => {
    setImportModalOpen((curVal) => {
      return !curVal;
    });
  };

  const clearUsers = () => {
    setUsersToImport([]);
  };

  const createUsers = async () => {
    toggleImportModal();
    //convert object to user object
    const users = usersToImport
      .filter((user) => user.selectedForImport)
      .map((importUser) => {
        const { selectedForImport, alreadyExists, ...user } = importUser;
        return user as Partial<User>;
      });
    //send users to backend
    await toast.promise(createUsersForTenant(tenantId, users), {
      pending: 'Creating Users',
      success: 'Users Created',
      error: 'There was an error',
    });
    clearUsers();
    return null;
  };

  const updateUsersSelected = (userEmail: string | null | undefined) => {
    if (!userEmail) {
      return;
    }
    setUsersToImport((oldUsers) => {
      return oldUsers.map((user) => {
        if (user.email === userEmail) {
          return { ...user, selectedForImport: !user.selectedForImport };
        }
        return user;
      });
    });
  };

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Family Name',
          accessor: 'familyName',
        },
        {
          Header: 'Given Name',
          accessor: 'givenName',
        },
        {
          Header: 'Import',
          accessor: 'selectedForImport',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            let display = '❌';
            let click = () => {
              ('');
            };
            if (!cellProps.rowsById[cellProps.row.id].original.alreadyExists) {
              display = cellProps.value ? '✅' : '⛔️';
              click = () => {
                updateUsersSelected(cellProps.rowsById[cellProps.row.id].original.email);
              };
            }
            return <span onClick={click}>{display}</span>;
          },
        },
      ] as Column<ImportUser>[],
    [],
  );
  const data = React.useMemo(() => usersToImport, [usersToImport]);
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  const renderSort = (column: HeaderGroup<ImportUser>): JSX.Element => {
    if (column.isSorted) {
      return <span>{column.isSortedDesc ? ' 🔽' : ' 🔼'}</span>;
    }
    return <span></span>;
  };
  const fileChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      if (e.currentTarget.files.length > 0) {
        const f = e.currentTarget.files.item(0);
        if (f) {
          setSelectedFile(f);
        }
      }
    }
  };
  const [selectedFile, setSelectedFile] = useState<File>();
  const startUserImport = async () => {
    if (selectedFile) {
      const fileText = await selectedFile?.text();
      const parsedUsers = parseCSVToUser(fileText);
      setUsersToImport(parsedUsers);
    }
  };

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Import Users</h5>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <div className="row">
            <div className="col">
              <InputGroup>
                <CustomInput id="fileInput" type="file" onChange={fileChange} accept=".csv" />
                <Button className={styles.scrbBtnBlue} disabled={selectedFile ? false : true} onClick={startUserImport}>
                  Parse File
                </Button>
                <InputGroupAddon addonType="append">
                  <Button color="danger" onClick={clearUsers}>
                    Clear
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            <div className="col-md-auto">
              <Button color="#f68b2b" style={{ backgroundColor: '#f68b2b', color: 'white' }} onClick={toggleImportModal}>
                Import Selected Users
              </Button>
            </div>
            <div className="col-md-auto">
              <Button
                className={styles.scrbBtnMrgnR}
                onClick={() =>
                  setUsersToImport((prev) =>
                    prev.map((user) => {
                      return { ...user, selectedForImport: true };
                    }),
                  )
                }
              >
                Select All
              </Button>
              <Button
                className={styles.scrbBtnMrgnR}
                onClick={() =>
                  setUsersToImport((prev) =>
                    prev.map((user) => {
                      user = { ...user, selectedForImport: !user.selectedForImport };
                      return user;
                    }),
                  )
                }
              >
                Invert
              </Button>
              <Button
                onClick={() =>
                  setUsersToImport((prev) =>
                    prev.map((user) => {
                      return { ...user, selectedForImport: false };
                    }),
                  )
                }
              >
                Deselect
              </Button>
            </div>
          </div>
          <br />
        </CardBody>
      </Card>
      <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
        <Table striped hover size="sm" id="auditTable" responsive>
          <thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.getHeaderProps().key}>
                      {column.render('Header')}
                      <span>{renderSort(column)}</span>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Modal toggle={toggleImportModal} isOpen={importModalOpen}>
          <ModalHeader>Import Users</ModalHeader>
          <ModalBody>
            <div>Import {usersToImport.filter((user) => user.selectedForImport).length} Users?</div>
          </ModalBody>
          <ModalFooter>
            <Button className={styles.scrbBtnBlue} onClick={toggleImportModal}>
              Cancel
            </Button>{' '}
            <AccessControl permissionId={'4'} action={'users'}>
              <Button type="submit" className={styles.scrbBtnOrange} onClick={createUsers}>
                Invite Users
              </Button>
            </AccessControl>
          </ModalFooter>
        </Modal>
      </Card>
    </div>
  );
};

export default UserImport;

import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { Alert, Col, Container, Row, Button, Form, FormGroup, FormFeedback, Label, Input, ListGroup, CardBody, Card } from 'reactstrap';
import { useParams } from 'react-router';
import { getRole, updateRole, getPermissions } from '../../api/roles';
import { User } from '../../types/user';
import { getUsersForRole } from '../../api/users';
import styles from '../../styles/archiveui.module.css';
import { useHistory } from 'react-router-dom';
import { PermissionAssociation, Permission, PermissionStatus, PermissionActions } from '../../types/role';
import RolePermission from '../roledetail/permission';
import PermissionModal from './permissionModal';
import UserTable from '../../users/userlisttable/usertable';
import { toast } from 'react-toastify';
import AccessControl from '../../users/userprofile/accesscontrol';

interface RoleDetailParams {
  id: string | undefined;
}

interface RoleDetailProps {
  tenantId: string;
}

const RoleDetail = ({ tenantId }: RoleDetailProps): JSX.Element => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const history = useHistory();
  const { id } = useParams<RoleDetailParams>();
  const [allPermissions, setAllPermissions] = useState<Record<string, Permission>>({});
  const permissionsAssociations: PermissionAssociation[] = [];
  const [usersForRole, setUsersForRole] = useState<User[]>([]);
  const [roleDetail, setRoleDetail] = useState({
    id: '',
    tenantId: tenantId,
    name: '',
    description: '',
    created: new Date(),
    modified: new Date(),
    permissions: permissionsAssociations,
  });
  const [touched, setTouched] = useState({
    roleName: false,
  });
  const [error, setError] = useState<string | null>(null);

  const setInitialState = () => {
    if (id && id !== '') {
      const getCurrentRole = async () => {
        const currentRole = await getRole(tenantId, id);
        setRoleDetail(() => {
          return {
            ...roleDetail,
            id: currentRole.id,
            tenantId: currentRole.tenantId,
            name: currentRole.name,
            description: currentRole.description,
            created: currentRole.created,
            modified: currentRole.modified,
            permissions: currentRole.permissions,
          };
        });
      };
      getCurrentRole();
    }
  };

  useEffect(() => {
    const getAllPermissions = async () => {
      const allpermissions = await getPermissions();
      setAllPermissions(allpermissions);
    };
    if (id !== undefined) {
      const getRoleUsers = async () => {
        const roleUsers = await getUsersForRole(tenantId, id);
        setUsersForRole(roleUsers);
      };
      getRoleUsers();
    }

    getAllPermissions();
    setInitialState();
  }, []);

  const permissionIdsForRole: string[] = [];
  roleDetail.permissions.forEach((perm) => {
    permissionIdsForRole.push(perm.permissionId);
  });

  const setAllPermissionToggle = (perm: Permission) => {
    const permId = perm.id;
    const permissionsCopy = roleDetail.permissions;
    if (permissionIdsForRole.includes(permId)) {
      let indexofPerm = 0;
      permissionsCopy.forEach((perm) => {
        if (perm.permissionId === permId) {
          indexofPerm = roleDetail.permissions.indexOf(perm);
        }
      });
      const permActionsCopy = permissionsCopy[indexofPerm].allowedActions;
      permActionsCopy.create = 'ALLOW';
      permActionsCopy.read = 'ALLOW';
      permActionsCopy.update = 'ALLOW';
      permActionsCopy.delete = 'ALLOW';
      setRoleDetail((prevValues) => {
        return { ...prevValues, ['permissions']: permissionsCopy };
      });
    } else {
      const currentPermActions: PermissionActions = { create: 'ALLOW', read: 'ALLOW', update: 'ALLOW', delete: 'ALLOW' };
      permissionsCopy.push({
        roleid: roleDetail.id,
        permissionId: permId,
        allowedActions: currentPermActions,
      });
      setRoleDetail((prevValues) => {
        return { ...prevValues, ['permissions']: permissionsCopy };
      });
    }
  };

  const togglePermisison = (event: ChangeEvent<HTMLInputElement>, perm: Permission) => {
    const permId = perm.id;
    const permAction = event.target.name;
    const permActionValue = event.target.value as PermissionStatus;
    const permissionsCopy = roleDetail.permissions;
    if (permissionIdsForRole.includes(permId)) {
      let indexofPerm = 0;
      permissionsCopy.forEach((perm) => {
        if (perm.permissionId === permId) {
          indexofPerm = roleDetail.permissions.indexOf(perm);
        }
      });
      const permActionsCopy = permissionsCopy[indexofPerm].allowedActions;
      if (permAction === 'create') {
        permActionsCopy.create = permActionValue;
      } else if (permAction === 'read') {
        permActionsCopy.read = permActionValue;
      } else if (permAction === 'update') {
        permActionsCopy.update = permActionValue;
      } else if (permAction === 'delete') {
        permActionsCopy.delete = permActionValue;
      }
      permissionsCopy[indexofPerm].allowedActions = permActionsCopy;
      setRoleDetail((prevValues) => {
        return { ...prevValues, ['permissions']: permissionsCopy };
      });
    } else {
      const currentPermActions: PermissionActions = { create: 'NONE', read: 'NONE', update: 'NONE', delete: 'NONE' };
      if (permAction === 'create') {
        currentPermActions.create = permActionValue;
      } else if (permAction === 'read') {
        currentPermActions.read = permActionValue;
      } else if (permAction === 'update') {
        currentPermActions.update = permActionValue;
      } else if (permAction === 'delete') {
        currentPermActions.delete = permActionValue;
      }
      permissionsCopy.push({
        roleid: roleDetail.id,
        permissionId: permId,
        allowedActions: currentPermActions,
      });
      setRoleDetail((prevValues) => {
        return { ...prevValues, ['permissions']: permissionsCopy };
      });
    }
  };

  const renderedPermissions = Object.keys(allPermissions).map((id, key) => {
    if (permissionIdsForRole.includes(id)) {
      let indexofPerm = 0;
      roleDetail.permissions.forEach((perm) => {
        if (perm.permissionId === id) {
          indexofPerm = roleDetail.permissions.indexOf(perm);
        }
      });
      return (
        <RolePermission
          togglePermission={togglePermisison}
          key={key}
          permission={allPermissions[id]}
          allowedActions={roleDetail.permissions[indexofPerm].allowedActions}
          actionsFromDefinititon={allPermissions[id].actions}
          toggleAll={setAllPermissionToggle}
        />
      );
    } else {
      return (
        <RolePermission
          togglePermission={togglePermisison}
          key={key}
          permission={allPermissions[id]}
          allowedActions={null}
          actionsFromDefinititon={allPermissions[id].actions}
          toggleAll={setAllPermissionToggle}
        />
      );
    }
  });

  const addToPartialRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleDetail((prevValues) => {
      return { ...prevValues, [event.target.name]: event.target.value };
    });
  };

  const saveRoleHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newRole = !id || id === '';
    if (roleDetail.permissions.length < 1) {
      setError('You must select at least one permission');
      return;
    } else {
      const response = await toast.promise(updateRole(newRole, roleDetail, tenantId), {
        pending: 'Updating Role',
        success: 'Role Updated',
        error: 'There was an error',
      });
      if (response === 200) {
        history.goBack();
        return response;
      }
      return response;
    }
  };

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (roleName: string) => {
    const errors = {
      roleName: '',
    };

    if (touched.roleName && roleName.trim().length < 1) {
      errors.roleName = 'Role Name is required';
    }
    return errors;
  };

  const formErrors = validate(roleDetail.name);

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  return (
    <div>
      <div className={styles.detailContainer}>
        <CardBody className={styles.searchBarHeader}>
          <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Role Detail</h5>
        </CardBody>
        <CardBody>
          <Form
            onSubmit={(event) => {
              validate(roleDetail.name);
              saveRoleHandler(event);
            }}
          >
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    value={roleDetail.name}
                    type="text"
                    name="name"
                    id="name"
                    invalid={formErrors.roleName !== ''}
                    onBlur={() => handleBlur('roleName')}
                    onChange={addToPartialRole}
                    required
                  />
                  <FormFeedback>{formErrors.roleName}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input value={roleDetail.description} type="textarea" name="description" id="description" placeholder="" onChange={addToPartialRole}></Input>
                </FormGroup>
              </Col>
            </Row>
            <div className={styles.contactsHeader}>
              <h3>Permissions</h3>
            </div>
            <ListGroup>{renderedPermissions}</ListGroup>
            {errorMessage}
            <div className={styles.detailBtnContainer}>
              <Button
                className={styles.scrbBtnBlueMargin}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <AccessControl permissionId={'roles'} action={'update'}>
                <Button className={styles.scrbBtnOrange}>Save</Button>
              </AccessControl>
            </div>
          </Form>
        </CardBody>
      </div>
      <PermissionModal modal={modal} toggle={toggle} newPermission={true} permission={undefined} />
      <UserTable users={usersForRole} tenantid={tenantId} subTable={true} />
    </div>
  );
};

export default RoleDetail;

import React, { useState, useEffect, ChangeEvent, FormEvent, KeyboardEvent } from 'react';
import AccountContact from './accountContact';
import styles from '../../styles/archiveui.module.css';
import { Col, Row, Button, Form, FormGroup, FormFeedback, Label, Input, ListGroup, Alert, CardBody } from 'reactstrap';
import Toast from '../../toast';
import { withRouter, useParams } from 'react-router';
import { updateAccount } from '../../api/accounts';
import { getAccount } from '../../api/accounts';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Contact } from '../../types/account';

interface DetailParams {
  tenantId: string | undefined;
}

const AccountDetail = () => {
  const history = useHistory();
  const { tenantId } = useParams<DetailParams>();
  const contacts: Contact[] = [];
  const [fullAccount, setAllValues] = useState({
    status: '',
    shortName: '',
    name: '',
    description: '',
    contacts: contacts,
  });
  const [error, setError] = useState<string | null>(null);

  const [partialAccount, setPartialAccount] = useState({
    tenantId: tenantId ? tenantId : '',
  });

  const setInitialState = () => {
    if (tenantId && tenantId !== '' && fullAccount.name === '') {
      const getCurrentAccount = async () => {
        const currentAccount = await getAccount(tenantId);
        setAllValues(() => {
          return {
            ...fullAccount,
            name: currentAccount.name,
            shortName: currentAccount.shortName,
            description: currentAccount.description,
            status: currentAccount.status,
            contacts: currentAccount.contacts,
          };
        });
      };
      getCurrentAccount();
    } else if (fullAccount.contacts.length < 1) {
      addContactHandler();
    }
  };

  useEffect(() => {
    setInitialState();
  }, []);

  const addToPartialAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartialAccount((prevValues) => {
      return { ...prevValues, [event.target.name]: event.target.value };
    });
    setAllValues((prevValues) => {
      return { ...prevValues, [event.target.name]: event.target.value };
    });
  };

  const saveAccountHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newAccount = !tenantId || tenantId === '';
    if (!fullAccount.contacts || fullAccount.contacts.length <= 0) {
      setError('You must add at least one contact.');
      console.log('setting error');
      return;
    } else {
      let primarySet = false;
      fullAccount.contacts.forEach((contact) => {
        if (contact.isPrimary) primarySet = true;
      });

      if (!primarySet) {
        setError('One contact must be designated as the primary contact.');
      } else {
        const response = await toast.promise(updateAccount(newAccount, partialAccount), {
          pending: 'Updating Account',
          success: 'Account Updated',
          error: 'There was an error',
        });
        if (response === 200) {
          history.push('/accountlist');
        } else {
          setError('There was an error updating this account, please try again.');
        }
      }
    }
  };

  const contactUpdateHandler = (targetName: string, e: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement> | any, index: number) => {
    const contactsCopy = [...fullAccount.contacts];
    const nameOfInput = targetName;
    const objectToUpdate = contactsCopy[index];
    if (nameOfInput == 'name') {
      objectToUpdate.name = e.target.value;
    } else if (nameOfInput == 'email') {
      objectToUpdate.email = e.target.value;
    } else if (nameOfInput == 'phone') {
      objectToUpdate.phone = e.target.value;
    } else if (nameOfInput == 'role') {
      objectToUpdate.role = e.target.value;
    } else if (nameOfInput == 'isPrimary') {
      contactsCopy.forEach((contact) => {
        contact.isPrimary = false;
      });
      objectToUpdate.isPrimary = e.target.checked;
    }
    contactsCopy[index] = objectToUpdate;
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  const addContactHandler = () => {
    const contactsCopy = [...fullAccount.contacts];
    if (contactsCopy.length < 1) {
      contactsCopy.push({
        name: '',
        email: '',
        phone: '',
        role: '',
        isPrimary: true,
      });
    } else {
      contactsCopy.push({
        name: '',
        email: '',
        phone: '',
        role: '',
        isPrimary: false,
      });
    }
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });

    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  const removeContactHandler = (indexOfContact: number) => {
    const contactsCopy = [...fullAccount.contacts];
    contactsCopy.splice(indexOfContact, 1);
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  const [touched, setTouched] = useState({
    status: false,
    shortName: false,
    name: false,
    description: false,
  });

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (status: string, shortName: string, name: string, description: string) => {
    const errors = {
      status: '',
      shortName: '',
      name: '',
      description: '',
    };

    if (touched.status && status.trim().length < 1) {
      errors.status = 'Status is a required field';
    }
    if (touched.shortName && shortName.trim().length < 1) {
      errors.shortName = 'Short Name is a required field';
    }
    if (touched.name && name.trim().length < 1) {
      errors.name = 'Name is a required field';
    }
    if (touched.description && description.trim().length < 1) {
      errors.description = 'Description is a required field';
    }

    return errors;
  };

  const formErrors = validate(fullAccount.status, fullAccount.shortName, fullAccount.name, fullAccount.description);

  return (
    <div>
      <div className={styles.detailContainer}>
        <CardBody className={styles.searchBarHeader}>
          <h5 className={styles.searchBarTitle}>Client Detail</h5>
        </CardBody>
        <CardBody>
          <Form onSubmit={(e) => saveAccountHandler(e)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    value={fullAccount.name}
                    type="text"
                    name="name"
                    id="name"
                    invalid={formErrors.name !== ''}
                    onBlur={() => handleBlur('name')}
                    onChange={addToPartialAccount}
                    required
                  />
                  <FormFeedback>{formErrors.name}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="shortName">Short Name</Label>
                  <Input
                    value={fullAccount.shortName}
                    type="text"
                    name="shortName"
                    id="shortName"
                    invalid={formErrors.shortName !== ''}
                    onBlur={() => handleBlur('shortName')}
                    onChange={addToPartialAccount}
                    required
                  />
                  <FormFeedback>{formErrors.shortName}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input
                    value={fullAccount.status}
                    type="select"
                    name="status"
                    id="status"
                    invalid={formErrors.status !== ''}
                    onBlur={() => handleBlur('status')}
                    onChange={addToPartialAccount}
                    required
                  >
                    <option></option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                  <FormFeedback>{formErrors.status}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    value={fullAccount.description}
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder=""
                    invalid={formErrors.description !== ''}
                    onBlur={() => handleBlur('description')}
                    onChange={addToPartialAccount}
                  ></Input>
                  <FormFeedback>{formErrors.description}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <div className={styles.contactsHeader}>
              <h3>Contacts</h3>
              <div>
                <Button className={styles.scrbBtnPrimary} size="sm" onClick={addContactHandler}>
                  Add Contact
                </Button>
              </div>
            </div>
            <ListGroup>
              {fullAccount.contacts.map((contact, index) => {
                return (
                  <AccountContact
                    name={contact.name}
                    email={contact.email}
                    phone={contact.phone}
                    role={contact.role}
                    isPrimary={contact.isPrimary}
                    key={index}
                    indexOfContact={index}
                    newAccount={tenantId && tenantId !== '' ? false : true}
                    updateFunction={contactUpdateHandler}
                    removeContactHandler={removeContactHandler}
                  />
                );
              })}
              {errorMessage}
            </ListGroup>
            <div className={styles.detailBtnContainer}>
              <Button
                className={styles.scrbBtnBlueMargin}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button className={styles.scrbBtnOrange}>Save</Button>
            </div>
          </Form>
        </CardBody>
      </div>
    </div>
  );
};

export default withRouter(AccountDetail);

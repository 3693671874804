import fetch from 'node-fetch';
import { Log, UserManager, UserManagerSettings } from 'oidc-client';
import { getBaseApiUrl } from '../api/helpers';

export default class AuthService {
  UserManager;
  redirectUri?: string;
  authority?: string;
  client_id?: string;

  constructor(config: UserManagerSettings) {
    Log.logger = console;
    //Log.level = Log.DEBUG;
    this.UserManager = new UserManager({ ...config });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });
    //console.log(`new AuthService created... ${JSON.stringify(config, null, 2)}`);
    this.redirectUri = config.redirect_uri;
    this.authority = config.authority;
    this.client_id = config.client_id;
    this.UserManager.events.addAccessTokenExpired(() => {
      this.logout();
      //this.signinRedirect();
    });
  }

  static getInstance = async (): Promise<AuthService> => {
    let url = `${getBaseApiUrl()}/v1/api/dashboard/oidc_configuration`;
    const external = sessionStorage.getItem('externalUser') === 'true';
    if (external) {
      url = `${getBaseApiUrl()}/v1/api/dashboard/external/oauth/.well-known/openid-configuration`;
    }
    const response = await (await fetch(url)).json();
    //console.log(`getInstance got response: ${JSON.stringify(response, null, 2)}`);
    if (process.env.NODE_ENV === 'development') {
      response.redirect_uri = 'http://localhost:3000/signin-callback.html';
      response.post_logout_redirect_uri = 'http://localhost:3000';
    }
    return new AuthService({ ...response });
  };

  signinRedirectCallback = async () => {
    return this.UserManager.signinRedirectCallback();
  };

  getUser = async (tenantId?: string) => {
    const user = await this.UserManager.getUser();
    if (!user && (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_SANDBOX_ENV === 'SANDBOX')) {
      console.log('getUser redirecting to signin');
      console.log(user);
      console.log(process.env.NODE_ENV);
      return await this.UserManager.signinRedirect();
    }
    return user;
  };

  getUserCognitoUsername = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      console.log('getUserCognitoUsername redirecting to signin');
      return await this.UserManager.signinRedirect();
    }
    return user.profile['cognito:username'];
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = async () => {
    console.log(`signinRedirect... setting redirect in local storage to ${this.redirectUri}`);
    localStorage.setItem('redirectUri', this.redirectUri ? this.redirectUri : '');
    localStorage.setItem('prevLocation', window.location.href);
    return this.UserManager.signinRedirect({
      redirect_uri: this.redirectUri,
    });
  };

  isAuthenticated = () => {
    const session = sessionStorage.getItem(`oidc.user:${this.authority}:${this.client_id}`) || '';
    if (!session) return false;
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${this.authority}:${this.client_id}`) || '');
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  createSigninRequest = async () => {
    return this.UserManager.createSigninRequest();
  };

  logout = async () => {
    await this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    return this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = async () => {
    await this.UserManager.signoutRedirectCallback();
    localStorage.clear();
    return this.UserManager.clearStaleState();
  };

  signinSilentCallback = () => {
    return {};
  };
}

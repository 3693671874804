import AuthService from '../auth/authservice';

const SANDBOX_URL = process.env.REACT_APP_SANDBOX_API_URL;
const TRANSFER_API_URL = 'https://transfer.scriborder.com';

export const getBaseApiUrl = (): string => {
  if (process.env.REACT_APP_SANDBOX_ENV === 'SANDBOX' && SANDBOX_URL) {
    return SANDBOX_URL;
  }
  return '';
};

export const getAuthToken = async (tenantId?: string) => {
  const auth = await AuthService.getInstance();
  const user = await auth.getUser(tenantId);
  if (user) {
    return `Bearer ${user?.access_token}`;
  }
  return '';
};

export const getTransferApiUrl = (): string => {
  return TRANSFER_API_URL;
};

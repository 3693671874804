import React, { useEffect, useState, useContext } from 'react';
import UserTable from '../userlisttable/usertable';
import { getUsersForTenant } from '../../api/users';
import { Loading } from '../../Loading';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';
import { User } from '../../types/user';

export type Status = 'PENDING_INVITE' | 'ACTIVE' | 'INACTIVE';

export interface TenantMembership {
  tenantid: string;
  roles: string[];
}

export interface UserPartial {
  id: string;
  tenantMembership?: TenantMembership[];
  cognitoIds?: string[];
  familyName?: string;
  givenName?: string;
  email?: string;
  phone?: string;
  status?: Status;
  thirdPartyAuth?: boolean;
}

interface UserListProps {
  tenantId: string;
}

export default function UserList({ tenantId }: UserListProps) {
  const { permissions } = useContext(UserContext);
  const history = useHistory();
  const [users, updateUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 25;
  let userAccessForPage: any;
  if (permissions[tenantId] != undefined) {
    userAccessForPage = permissions[tenantId]['users'].actions.read;
  }

  const fetchUsers = async () => {
    updateUsers(await getUsersForTenant(tenantId));
  };

  useEffect(() => {
    const setUsers = async () => {
      await fetchUsers();
      setLoading(false);
    };
    if (userAccessForPage === 'DENY' || userAccessForPage === 'NONE' || permissions === undefined) {
      toast.error('Not Authorized');
      history.push('/notauthorized');
    } else {
      setUsers();
    }
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {loading && <Loading />}
      {!loading && <UserTable tenantid={tenantId} users={currentUsers} fetchUsers={fetchUsers} subTable={false} />}
    </div>
  );
}

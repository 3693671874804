import React, { useRef, useContext, useState, FormEvent } from 'react';
import { Col, Row, Button, Form, FormGroup, FormFeedback, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import { MdPhotoCamera } from 'react-icons/md';
import styles from '../../styles/archiveui.module.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { DashboardExternalUser } from '../../types/user';
import { UserContext } from '../../contexts/UserContext';
import { updateUserProfile } from '../../api/userprofile';
import { toast } from 'react-toastify';
import { PermissionActions } from '../../types/role';
import { Account } from '../../types/account';

export interface UserPermission {
  uri: string;
  actions: PermissionActions;
}

export interface UserProfile {
  user: DashboardExternalUser;
  associatedAccounts: Account[];
  permissions: Record<string, Record<string, UserPermission>>; // structure is { "${tenantid}": {"${permissionId}": {create: true, update: true, read: false, delete: true}}}
}

const UserProfilePage = () => {
  const { user } = useContext(UserContext);
  const [userProfile, setUserProfile] = useState({
    id: user.id,
    familyName: user.familyName,
    givenName: user.givenName,
    email: user.email,
    phone: user.phone,
  });
  const [touched, setTouched] = useState({
    familyName: false,
    givenName: false,
    email: false,
    phone: false,
  });

  const addToPartialUserProfile = (formattedValue: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target.name != undefined) {
      setUserProfile((prevValues) => {
        return { ...prevValues, [event.target.name]: event.target.value };
      });
      setUserProfile((prevValues) => {
        return { ...prevValues, [event.target.name]: event.target.value };
      });
    } else if (formattedValue != '') {
      setUserProfile((prevValues) => {
        return { ...prevValues, ['phone']: formattedValue };
      });
      setUserProfile((prevValues) => {
        return { ...prevValues, ['phone']: formattedValue };
      });
    }
  };

  // stubbed out profile image handlers
  const profileImg = useRef(null);
  const openFileDialog = () => {
    if (profileImg != null) {
      document.getElementById('file')?.click();
    }
  };
  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    //console.log('files:', files);
  };

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (givenName: string, familyName: string, email: string, phone: string) => {
    const errors = {
      givenName: '',
      familyName: '',
      email: '',
      phone: '',
    };

    if (touched.givenName && givenName.trim().length < 1) {
      errors.givenName = 'First Name is required';
    }
    if (touched.familyName && familyName.trim().length < 1) {
      errors.familyName = 'Last Name is required';
    }
    if (
      touched.email &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.trim(),
      ) === false
    ) {
      errors.email = 'Email provided is not a valid email';
    }
    if (touched.phone && phone.trim().length < 1) {
      errors.phone = 'Phone Number is required';
    }
    return errors;
  };

  const formErrors = validate(userProfile.givenName, userProfile.familyName, userProfile.email, userProfile.phone);

  const updateProfileHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response = await toast.promise(updateUserProfile(userProfile), {
      pending: 'Updating Profile',
      success: 'Profile Updated',
      error: 'There was an error',
    });
    if (response === 200) {
      return response;
    }
    return response;
  };

  return (
    <div className={styles.detailContainer}>
      <CardBody className={styles.searchBarHeader}>
        <h5 className={styles.searchBarTitle}>User Profile</h5>
      </CardBody>
      <CardBody className={styles.profileCardContainer}>
        <div className={styles.profileImageContainer}>
          <img className={styles.profileImage} src="https://upload.wikimedia.org/wikipedia/commons/8/85/Elon_Musk_Royal_Society_%28crop1%29.jpg" alt="" />
          <MdPhotoCamera onClick={openFileDialog} className={styles.editIcon} />
          <Input type="file" name="file" id="file" onChange={handleFileSelected} ref={profileImg} style={{ display: 'none' }} />
        </div>
        <h5 style={{ fontSize: '.8125em', fontWeight: 600 }}>
          {userProfile.givenName} {userProfile.familyName}
        </h5>
        <p style={{ marginTop: '-7px' }}>Company</p>
      </CardBody>
      <CardBody>
        <Form
          onSubmit={(event) => {
            validate(userProfile.givenName, userProfile.familyName, userProfile.email, userProfile.phone);
            updateProfileHandler(event);
          }}
        >
          <Row form>
            <Col>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  name="givenName"
                  value={userProfile.givenName}
                  invalid={formErrors.givenName !== ''}
                  onBlur={() => handleBlur('givenName')}
                  onChange={(e) => addToPartialUserProfile('', e)}
                />
                <FormFeedback>{formErrors.givenName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="familyName">Last Name</Label>
                <Input
                  name="familyName"
                  value={userProfile.familyName}
                  invalid={formErrors.familyName !== ''}
                  onBlur={() => handleBlur('familyName')}
                  onChange={(e) => addToPartialUserProfile('', e)}
                />
                <FormFeedback>{formErrors.familyName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  name="email"
                  value={userProfile.email}
                  invalid={formErrors.email !== ''}
                  onBlur={() => handleBlur('email')}
                  onChange={(e) => addToPartialUserProfile('', e)}
                />
                <FormFeedback>{formErrors.email}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <PhoneInput
                  value={userProfile.phone}
                  inputStyle={{ width: '100%' }}
                  country="us"
                  onBlur={() => handleBlur('phone')}
                  onChange={(formattedValue) => addToPartialUserProfile(formattedValue)}
                />
                <FormFeedback style={{ display: formErrors.phone != '' ? 'flex' : 'none' }}>{formErrors.phone}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <div className={styles.detailBtnContainer}>
            <Button className={styles.scrbBtnOrange}>Save</Button>
          </div>
        </Form>
      </CardBody>
    </div>
  );
};

export default UserProfilePage;

import fetch from 'node-fetch';
import { UserPartial } from '../users/userlist';
import { User } from '../types/user';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getUsers = async (): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/user`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUsersForTenant = async (tenantId: string): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/${tenantId}/user`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

// url: '/tenantUserTestID/user/addTenantUserId'
export const getUser = async (id: string, tenantid: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/${tenantid}/user/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUserByCognitoId = async (cognitoId: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/user/${cognitoId}`;
  const response = await fetch(url);
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUser = async (newUser: boolean, userData: UserPartial, tenantId: string, id: string): Promise<number> => {
  const method = newUser ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/${tenantId}/user/${id}`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const setUsersForRole = async (tenantId: string, roleId: string, userIds: string[]): Promise<number> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userIds),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/${tenantId}/role/${roleId}/users`, requestOptions);
  if (response.ok) {
    return response.json() as Promise<number>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const removeUserForRole = async (tenantId: string, roleId: string, userId: string): Promise<number> => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/${tenantId}/role/${roleId}/users/${userId}`, requestOptions);
  if (response.ok) {
    return response.json() as Promise<number>;
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const createUsersForTenant = async (tenantId: string, users: Partial<User>[] | Partial<User>, roles?: string[]): Promise<number> => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ users, roles }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/${tenantId}/user`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUsersForRole = async (tenantId: string, roleId: string): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/${tenantId}/role/${roleId}/users`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const inviteScribblesAdmin = async (email: string) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ email: email }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/user`, requestOptions);
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else if (response.status === 405) {
    return Promise.reject('User already exists');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const checkExternalUser = async (token: string): Promise<any> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/externalauth/verify`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  });
  if (response.ok) {
    return response.json() as Promise<any>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Card, Input, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import SearchBar from './searchbar';
import { AuditEntry } from '../../types/audit';

interface AuditLogProps {
  audits: AuditEntry[];
  updateActorFilter: Dispatch<SetStateAction<string>>;
  updateTargetFilter: Dispatch<SetStateAction<string>>;
  updateResultFilter: Dispatch<SetStateAction<string>>;
  updateApplicationFilter: Dispatch<SetStateAction<string>>;
  updateStartDate: Dispatch<SetStateAction<string>>;
  updateEndDate: Dispatch<SetStateAction<string>>;
  actorFilter: string;
  targetFilter: string;
  resultFilter: string;
  applicationFilter: string;
  startDate: string;
  endDate: string;
  searchAudits: () => void;
}

const AuditTable = ({
  audits,
  updateActorFilter,
  updateTargetFilter,
  updateResultFilter,
  updateApplicationFilter,
  updateStartDate,
  updateEndDate,
  actorFilter,
  targetFilter,
  resultFilter,
  applicationFilter,
  startDate,
  endDate,
  searchAudits,
}: AuditLogProps): JSX.Element => {
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time', // accessor is the "key" in the data
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          return <tr>{moment(props.time).format('MMM DD YYYY')}</tr>;
        },
      },
      {
        Header: 'Tenant ID',
        accessor: 'tenantId',
      },
      {
        Header: 'Application',
        accessor: 'application',
      },
      {
        Header: 'Actor',
        accessor: 'actor',
      },
      {
        Header: 'Source Type',
        accessor: 'sourceType',
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Source IP',
        accessor: 'sourceip',
      },
      {
        Header: 'Target',
        accessor: 'target',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Result',
        accessor: 'result',
      },
      {
        Header: 'Meta',
        accessor: 'meta',
      },
    ],
    [],
  );

  const data = audits;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 25 } }, useSortBy, usePagination);

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <div>
      <SearchBar
        updateActorFilter={updateActorFilter}
        updateTargetFilter={updateTargetFilter}
        updateResultFilter={updateResultFilter}
        updateApplicationFilter={updateApplicationFilter}
        updateStartDate={updateStartDate}
        updateEndDate={updateEndDate}
        actorFilter={actorFilter}
        targetFilter={targetFilter}
        resultFilter={resultFilter}
        applicationFilter={applicationFilter}
        startDate={startDate}
        endDate={endDate}
        searchAudits={searchAudits}
      />
      <div className={styles.tableContainer}>
        <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
          <Table bordered striped hover size="sm" id="auditTable" responsive>
            <thead className={styles.tableHeader}>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.Header === 'Time') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === 'Time') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td style={{ paddingLeft: '1.25em' }} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </div>
      <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
        <div style={{ marginRight: '.5em' }}>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Button>{' '}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </Button>{' '}
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </Button>{' '}
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Button>{' '}
        </div>
        <div style={{ marginRight: '.5em' }}>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
        <Input
          style={{ maxWidth: '10em' }}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};

export default AuditTable;

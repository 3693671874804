import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { Link } from 'react-router-dom';
import { inviteScribblesAdmin } from '../../api/users';
import { updateAccount } from '../../api/accounts';
import { toast } from 'react-toastify';
import UserInviteModal from '../users/userinvite/userinvitemodal';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  updateShowInactive: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  showInactive: boolean;
}

const SearchBar = ({ updateSearchText, updateShowInactive, searchText, showInactive }: searchBarProps): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false);
  const handleActiveInactive = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val === 'active') {
      updateShowInactive(false);
    } else {
      updateShowInactive(true);
    }
  };

  const clearFilters = () => {
    updateSearchText('');
    updateShowInactive(false);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const inviteUser = async (email: string) => {
    await toast.promise(inviteScribblesAdmin(email), {
      pending: 'Sending Request',
      success: 'Invite Sent',
      error: {
        render(error) {
          return error.data;
        },
      },
    });
    toggle();
  };
  return (
    <div>
      <CardBody className={styles.searchBarHeader}>
        <h5 className={styles.searchBarTitle}>Accounts</h5>
      </CardBody>
      <CardBody className={styles.searchBarBody}>
        <Form>
          <Row form className={styles.rowContainer}>
            <Col md="6">
              <FormGroup>
                <Input type="text" id="search" placeholder="Search Name, ID" value={searchText} onChange={(e) => updateSearchText(e.target.value)} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  placeholder="Status"
                  value={showInactive ? 'inactive' : 'active'}
                  onChange={handleActiveInactive}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md=".5">
              <Button id="clearFilterBtn" className={styles.filterBtn} onClick={clearFilters}>
                <MdClear />
              </Button>
              <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
              <Button
                id="addAccountBtn"
                color="#f68b2b"
                style={{ backgroundColor: '#f68b2b', color: 'white' }}
                tag={Link}
                to="/accountdetail"
                onClick={clearFilters}
              >
                <BsPlus />
              </Button>
              <UncontrolledTooltip target="addAccountBtn">Add Account</UncontrolledTooltip>
              <Button id="inviteUserBtn" color="primary" onClick={toggle} style={{ marginLeft: '7px' }}>
                Invite Scribbles Admin
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <UserInviteModal toggle={toggle} modal={modal} scribblesAdmin={true} inviteUser={inviteUser} />
    </div>
  );
};

export default SearchBar;

import fetch from 'node-fetch';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const fetchDistrictsByState = async (stateCode: string) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/data/state/${stateCode}/district`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  });
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const fetchDistrict = async (districtId: string) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/data/district/${districtId}`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  });
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const fetchSchoolsByDistrict = async (districtId: string) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/data/district/${districtId}/school`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  });
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const fetchSchoolById = async (schoolId: string) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/dashboard/data/school/${schoolId}`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  });
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

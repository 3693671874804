import React from 'react';
import { Route } from 'react-router-dom';
import { AuthConsumer } from './authprovider';

interface RouteProps {
  component: (props: any) => JSX.Element;
  path: string;
  exact?: boolean;
  componentProps?: Record<string, any>;
}

export const PrivateRoute = ({ component, componentProps, ...rest }: RouteProps) => {
  // eslint-disable-next-line react/display-name
  const renderFn = (RenderComponent: (props: any) => JSX.Element) => (props: any) =>
    (
      <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if ((process.env.NODE_ENV === 'development' && process.env.REACT_APP_SANDBOX_ENV !== 'SANDBOX') || (!!RenderComponent && isAuthenticated())) {
            return <RenderComponent {...props} />;
          } else {
            console.log('about to redirect 2');
            signinRedirect();
            return null;
          }
        }}
      </AuthConsumer>
    );

  return <Route {...rest} render={renderFn(component)} />;
};

import React, { useContext, useEffect, useState } from 'react';
import RoleTable from '../rolelisttable/roletable';
import { getRoles } from '../../api/roles';
import { Loading } from '../../Loading';
import { UserContext } from '../../contexts/UserContext';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Role } from '../../types/role';

interface RoleListProps {
  tenantId: string;
}

const RolesList = ({ tenantId }: RoleListProps) => {
  const { permissions } = useContext(UserContext);
  const history = useHistory();
  const [roles, updateRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(true);
  let userAccessForPage: any;
  if (permissions[tenantId] != undefined) {
    userAccessForPage = permissions[tenantId]['roles'].actions.read;
  }

  useEffect(() => {
    const setRoles = async () => {
      const roles = await getRoles(tenantId);
      updateRoles(roles);
      setLoading(false);
    };
    if (userAccessForPage === 'DENY' || userAccessForPage === 'NONE' || permissions === undefined) {
      toast.error('Not Authorized');
      history.push('/notauthorized');
    } else {
      setRoles();
    }
  }, []);

  const filteredRoles = roles.filter((role) => {
    return role.tenantId === tenantId.toString();
  });

  return (
    <div>
      {loading && <Loading />}
      {!loading && <RoleTable roles={filteredRoles} />}
    </div>
  );
};

export default RolesList;

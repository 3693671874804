import React, { useState, useEffect, FormEvent } from 'react';
import { Col, CardBody, Row, Button, Form, FormGroup, Label, Input, Alert, CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import { useParams } from 'react-router';
import { getUser, updateUser, setUsersForRole, removeUserForRole } from '../../api/users';
import { getRoles } from '../../api/roles';
import styles from '../../styles/archiveui.module.css';
import { TenantMembership, Status } from '../userlist/index';
import { useHistory } from 'react-router-dom';
import Toast from '../../toast';
import 'react-phone-input-2/lib/bootstrap.css';
import { toast } from 'react-toastify';
import AccessControl from '../userprofile/accesscontrol';
import { Role } from '../../types/role';

interface UserDetailParams {
  id: string | undefined;
}

interface UserDetailParams {
  id: string | undefined;
}

interface UserDetailProps {
  tenantid: string;
}

const UserDetail = ({ tenantid }: UserDetailProps): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<UserDetailParams>();
  const tenantMembership: TenantMembership[] = [{ tenantid: tenantid, roles: [] }];
  const cognitoIds: Array<string> = [];
  const userStatus: Status = 'ACTIVE';
  const [roles, updateRoles] = useState<Role[]>([]);
  const [userDetail, setUserDetail] = useState({
    id: '',
    tenantMembership: tenantMembership,
    cognitoIds: cognitoIds,
    familyName: '',
    givenName: '',
    email: '',
    phone: '',
    status: userStatus,
    thirdPartyAuth: false,
  });
  const [partialUser, setPartialUser] = useState({
    id: id ? id : '',
  });
  const [error, setError] = useState<string | null>(null);

  const setInitialState = () => {
    if (id && id !== '') {
      const getCurrentUser = async () => {
        const currentUser = await getUser(id, tenantid);
        setUserDetail(() => {
          return {
            ...userDetail,
            id: currentUser.id,
            tenantMembership: currentUser.tenantMembership,
            cognitoIds: currentUser.cognitoIds,
            familyName: currentUser.familyName,
            givenName: currentUser.givenName,
            email: currentUser.email,
            phone: currentUser.phone,
            status: userDetail.status,
            thirdPartyAuth: currentUser.thirdPartyAuth,
          };
        });
      };
      getCurrentUser();
    }
  };

  useEffect(() => {
    setInitialState();
    const setRoles = async () => {
      const roles = await getRoles(tenantid);
      updateRoles(roles);
    };
    setRoles();
  }, []);

  const addToPartialUser = (key: string, value: any) => {
    setUserDetail((prevValues) => {
      return { ...prevValues, [key]: value };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  const addRoleIdHandler = (val: string) => {
    const tenantMembershipCopy = userDetail.tenantMembership;
    const membershipToEdit = tenantMembershipCopy.filter((e) => e.tenantid === tenantid);
    membershipToEdit[0].roles.push(val);
    setUserDetail((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
  };

  const removeRoleIdHandler = (val: string) => {
    const tenantMembershipCopy = userDetail.tenantMembership;
    const membershipToEdit = tenantMembershipCopy.filter((e) => e.tenantid === tenantid);
    for (let i = 0; i < membershipToEdit[0].roles.length; i++) {
      if (membershipToEdit[0].roles[i] === val) {
        membershipToEdit[0].roles.splice(i, 1);
      }
    }
    setUserDetail((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
  };

  const addUserRole = async (roleId: string) => {
    const response = await setUsersForRole(tenantid, roleId, [userDetail.id]);
    if (response === 204 || response === 200) {
      // do nothing
    } else {
      setError('There was an error updating this user, please try again.');
    }
  };

  const removeUserRole = async (roleId: string) => {
    const response = await removeUserForRole(tenantid, roleId, userDetail.id);
    if (response === 204 || response === 200) {
      // do nothing
    } else {
      setError('There was an error updating this user, please try again.');
    }
  };

  const saveUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedRoles = userDetail.tenantMembership.filter((e) => e.tenantid === tenantid)[0].roles;
    if (!selectedRoles || selectedRoles.length === 0) {
      setError('You must select at least one role.');
      return;
    }
    roles.forEach(async (role) => {
      if (userDetail.tenantMembership.filter((e) => e.tenantid === tenantid)[0].roles.includes(role.id)) {
        await addUserRole(role.id);
      } else {
        await removeUserRole(role.id);
      }
    });
    history.push('/users');
  };

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  const membershipRoleIds = userDetail.tenantMembership.find((membership) => {
    return membership.tenantid === tenantid;
  })?.roles;

  return (
    <div className={styles.detailContainer}>
      <CardBody className={styles.searchBarHeader}>
        <h5 className={styles.searchBarTitle}>User Detail</h5>
      </CardBody>
      <CardBody>
        <Form
          onSubmit={async (event) => {
            await saveUserHandler(event);
          }}
        >
          <Row form>
            <Col>
              <FormGroup>
                <Label for="name">First Name</Label>
                <Input value={userDetail.givenName} type="text" name="givenName" id="givenName" readOnly />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Last Name</Label>
                <Input value={userDetail.familyName} type="text" name="name" id="name" readOnly />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input value={userDetail.email} type="text" name="email" id="email" readOnly />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <Input value={userDetail.phone} type="text" name="phone" id="phone" readOnly />
              </FormGroup>
            </Col>
          </Row>
          <Col md={6} style={{ display: userDetail.id == '' ? 'flex' : 'none' }}>
            <FormGroup>
              <Row>
                <ListGroupItem style={{ border: '1px solid #fff' }}>
                  <CustomInput
                    checked={userDetail.thirdPartyAuth}
                    onChange={(e) => {
                      if (userDetail.thirdPartyAuth) {
                        addToPartialUser('thirdPartyAuth', false);
                      } else {
                        addToPartialUser('thirdPartyAuth', true);
                      }
                    }}
                    type="checkbox"
                    label="Logging in with 3rd Party/Cognito"
                    id="thirdPartyLogin"
                  />
                </ListGroupItem>
              </Row>
            </FormGroup>
          </Col>
          <Row>
            <Col>
              <FormGroup>
                <div>
                  <h3>Roles</h3>
                </div>
                <ListGroup style={{ maxHeight: '25em', overflow: 'auto', border: '1px solid #ced4da' }}>
                  {roles?.map((role, index) => {
                    return (
                      <ListGroupItem key={index} className={styles.roleListItemContainer}>
                        <CustomInput
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              addRoleIdHandler(e.target.value);
                            } else {
                              removeRoleIdHandler(e.target.value);
                            }
                          }}
                          type="checkbox"
                          value={role.id}
                          key={index}
                          label={`${role.name}`}
                          id={index}
                          checked={membershipRoleIds?.includes(role.id) ? true : false}
                        />
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </FormGroup>
            </Col>
          </Row>
          {errorMessage}
          <div className={styles.detailBtnContainer}>
            <Button
              className={styles.scrbBtnBlueMargin}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <AccessControl permissionId={'users'} action={'update'}>
              <Button className={styles.scrbBtnOrange}>Save</Button>
            </AccessControl>
          </div>
        </Form>
      </CardBody>
    </div>
  );
};

export default UserDetail;

import * as React from 'react';
import { Spinner } from 'reactstrap';

export const Loading = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 56px)', flexGrow: 1 }}>
      <Spinner style={{ height: '10em', width: '10em', color: '#2582b5' }} />
    </div>
  );
};

import React, { FunctionComponent, useState } from 'react';
import AuthService from './authservice';

export const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  getUser: () => ({}),
});

interface AuthProviderProps {
  children: JSX.Element | JSX.Element[];
  authProvider: AuthService;
}
export const AuthProvider = (props: AuthProviderProps) => {
  const [authService] = useState<AuthService>(props.authProvider);
  return <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;

import React, { Dispatch, SetStateAction, useState, FormEvent } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { Link } from 'react-router-dom';
import UserInviteModal from '../userinvite/userInvitemodal';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
  tenantid: string;
  subListStyle: boolean;
  fetchUsers?: () => Promise<void>;
}

const SearchBar = ({ updateSearchText, searchText, tenantid, subListStyle, fetchUsers }: searchBarProps): JSX.Element => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const clearFilters = () => {
    updateSearchText('');
  };

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 className={styles.searchBarTitle}>Users</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <Col>
                <FormGroup>
                  <Input
                    type="text"
                    id="search"
                    placeholder="Search Name, ID"
                    value={searchText}
                    onChange={(e) => updateSearchText(e.target.value.toLowerCase())}
                  />
                </FormGroup>
              </Col>

              <Col md=".1">
                <Button id="clearFilterBtn" className={styles.filterBtn} onClick={clearFilters}>
                  <MdClear />
                </Button>
                <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
                <Button
                  id="scrbBtnBlueMargin"
                  onClick={toggle}
                  className={styles.scrbBtnBlueMargin}
                  style={{ display: subListStyle ? 'none' : 'inline-block' }}
                >
                  Invite User
                </Button>
                <UncontrolledTooltip target="scrbBtnBlueMargin">Invite User</UncontrolledTooltip>
                <Button
                  id="importUsersBtn"
                  tag={Link}
                  to="/importusers"
                  style={{ display: subListStyle ? 'none' : 'inline-block' }}
                  className={styles.scrbBtnOrange}
                >
                  Import Users
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <UserInviteModal toggle={toggle} modal={modal} tenantid={tenantid} fetchUsers={fetchUsers} />
    </div>
  );
};

export default SearchBar;

/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, FormEvent } from 'react';
import { Col, Container, Row, Button, Form, FormFeedback, FormGroup, Label, Input, Alert, CustomInput, ListGroup, ListGroupItem, CardBody } from 'reactstrap';
import Toast from '../../../toast';
import { useParams } from 'react-router';
import { getUser, updateUser } from '../../../api/accounts';
import { getRoles, setUsersForRole, removeUserForRole } from '../../../api/roles';
import styles from '../../../styles/archiveui.module.css';
import { TenantMembership } from '../../../types/user';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { toast } from 'react-toastify';
import { Role } from '../../../types/role';
import { UserPartial } from '../../../types/user';

interface UserDetailParams {
  tenantId: string;
  id: string | undefined;
}

const UserDetail = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<UserDetailParams>();
  const { tenantId } = useParams<UserDetailParams>();
  const tenantMembership: TenantMembership[] = [{ tenantid: tenantId, roles: [], status: 'ACTIVE' }];
  const cognitoIds: Array<string> = [];
  const [roles, updateRoles] = useState<Role[]>([]);
  const [userDetail, setUserDetail] = useState({
    id: '',
    tenantMembership: tenantMembership,
    cognitoIds: cognitoIds,
    familyName: '',
    givenName: '',
    email: '',
    phone: '',
  });
  const [partialUser, setPartialUser] = useState<UserPartial>({
    id: id ? id : '',
  });
  const [touched, setTouched] = useState({
    familyName: false,
    givenName: false,
    email: false,
    phone: false,
  });
  const [error, setError] = useState<string | null>(null);

  const setInitialState = () => {
    if (id && id !== '') {
      const getCurrentUser = async () => {
        const currentUser = await getUser(id);
        setUserDetail(() => {
          return {
            ...userDetail,
            id: currentUser.id,
            tenantMembership: currentUser.tenantMembership,
            cognitoIds: currentUser.cognitoIds,
            familyName: currentUser.familyName,
            givenName: currentUser.givenName,
            email: currentUser.email,
            phone: currentUser.phone,
          };
        });
      };
      getCurrentUser();
    }
  };

  useEffect(() => {
    setInitialState();
    const setRoles = async () => {
      const roles = await getRoles(tenantId);
      updateRoles(roles);
    };
    setRoles();
  }, []);

  const addToPartialUser = (formattedValue: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target.name != undefined) {
      setUserDetail((prevValues) => {
        return { ...prevValues, [event.target.name]: event.target.value };
      });
      setPartialUser((prevValues) => {
        return { ...prevValues, [event.target.name]: event.target.value };
      });
    } else if (formattedValue != '') {
      setUserDetail((prevValues) => {
        return { ...prevValues, ['phone']: formattedValue };
      });
      setPartialUser((prevValues) => {
        return { ...prevValues, ['phone']: formattedValue };
      });
    }
  };

  const saveUserDetails = async () => {
    const newUser = !id || id === '';
    if (userDetail.tenantMembership.filter((e) => e.tenantid === tenantId)[0].roles.length === 0) {
      setError('You must select at least one role');
      return;
    } else {
      const response = await toast.promise(updateUser(newUser, partialUser), {
        pending: 'Updating User',
        success: 'User Updated',
        error: 'There was an error',
      });
      if (response === 200) {
        history.goBack();
      } else {
        setError('There was an error updating this user, please try again.');
      }
    }
  };

  const addRoleIdHandler = (val: string) => {
    const tenantMembershipCopy = userDetail.tenantMembership;
    const membershipToEdit = tenantMembershipCopy.filter((e) => {
      return e.tenantid == tenantId;
    });
    membershipToEdit[0].roles.push(val);
    setUserDetail((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
  };

  const removeRoleIdHandler = (val: string) => {
    const tenantMembershipCopy = userDetail.tenantMembership;
    const membershipToEdit = tenantMembershipCopy.filter((e) => e.tenantid === tenantId);
    for (let i = 0; i < membershipToEdit[0].roles.length; i++) {
      if (membershipToEdit[0].roles[i] === val) {
        membershipToEdit[0].roles.splice(i, 1);
      }
    }
    setUserDetail((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, ['tenantMembership']: tenantMembershipCopy };
    });
  };

  const addUserRole = async (roleId: string) => {
    const response = await setUsersForRole(tenantId, roleId, [userDetail.id]);
    if (response === 204) {
      // do nothing
    } else {
      setError('There was an error updating this user, please try again.');
    }
  };

  const removeUserRole = async (roleId: string) => {
    const response = await removeUserForRole(tenantId, roleId, userDetail.id);
    if (response === 204) {
      // do nothing
    } else {
      setError('There was an error updating this user, please try again.');
    }
  };

  const saveUserHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    roles.forEach((role) => {
      if (userDetail.tenantMembership.filter((e) => e.tenantid === tenantId)[0].roles.includes(role.id)) {
        addUserRole(role.id);
      } else {
        removeUserRole(role.id);
      }
    });
    saveUserDetails();
  };

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (familyName: string, givenName: string, email: string, phone: string) => {
    const errors = {
      familyName: '',
      givenName: '',
      email: '',
      phone: '',
    };

    if (touched.familyName && familyName.trim().length < 1) {
      errors.familyName = 'Last Name is required';
    }
    if (touched.givenName && givenName.trim().length < 1) {
      errors.givenName = 'First Name is required';
    }
    if (touched.email && /\S+@\S+\.\S+/.test(email.trim()) === false) {
      errors.email = 'Email provided is not a valid email';
    }
    if (touched.phone && phone.trim().length < 1) {
      errors.phone = 'Phone Number is required';
    }

    return errors;
  };

  const formErrors = validate(userDetail.familyName, userDetail.givenName, userDetail.email, userDetail.phone);

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  const membershipRoleIds = userDetail.tenantMembership.find((membership) => {
    return membership.tenantid === tenantId;
  })?.roles;
  return (
    <div className={styles.detailContainer}>
      <CardBody className={styles.searchBarHeader}>
        <h5 className={styles.searchBarTitle}>User Detail</h5>
      </CardBody>
      <CardBody>
        <Form
          onSubmit={(event) => {
            saveUserHandler(event);
            validate(userDetail.familyName, userDetail.givenName, userDetail.email, userDetail.phone);
          }}
        >
          <Row form>
            <Col>
              <FormGroup>
                <Label for="givenName">First Name</Label>
                <Input
                  value={userDetail.givenName}
                  type="text"
                  name="givenName"
                  id="givenName"
                  invalid={formErrors.givenName !== ''}
                  onBlur={() => handleBlur('givenName')}
                  onChange={(event) => addToPartialUser('', event)}
                  required
                />
                <FormFeedback>{formErrors.givenName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="familyName">Last Name</Label>
                <Input
                  value={userDetail.familyName}
                  type="text"
                  name="familyName"
                  id="familyName"
                  invalid={formErrors.familyName !== ''}
                  onBlur={() => handleBlur('familyName')}
                  onChange={(event) => addToPartialUser('', event)}
                  required
                />
                <FormFeedback>{formErrors.familyName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Email</Label>
                <Input
                  value={userDetail.email}
                  type="text"
                  name="email"
                  id="email"
                  invalid={formErrors.email !== ''}
                  onBlur={() => handleBlur('email')}
                  onChange={(event) => addToPartialUser('', event)}
                  required
                />
                <FormFeedback>{formErrors.email}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <PhoneInput
                  country="us"
                  preferredCountries={['us']}
                  value={userDetail.phone}
                  isValid={formErrors.phone === ''}
                  onBlur={() => handleBlur('phone')}
                  inputStyle={{ width: '100%' }}
                  onChange={(formattedValue) => addToPartialUser(formattedValue, undefined)}
                />
                <FormFeedback style={{ display: formErrors.phone != '' ? 'flex' : 'none' }}>{formErrors.phone}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <div>
                  <h3>Roles</h3>
                </div>
                <ListGroup style={{ maxHeight: '25em', overflow: 'auto', border: '1px solid #ced4da' }}>
                  {roles?.map((role, index) => {
                    return (
                      <ListGroupItem key={index} className={styles.roleListItemContainer}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <CustomInput
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                addRoleIdHandler(e.target.value);
                              } else {
                                removeRoleIdHandler(e.target.value);
                              }
                            }}
                            type="checkbox"
                            value={role.id}
                            key={index}
                            label={`${role.name}`}
                            id={index}
                            checked={membershipRoleIds?.includes(role.id) ? true : false}
                          />
                          <a href={`roledetail/${role.id}`} target="_blank" style={{ color: 'black' }}>
                            View Role
                          </a>
                        </div>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </FormGroup>
            </Col>
          </Row>
          {errorMessage}
          <div className={styles.detailBtnContainer}>
            <Button
              className={styles.scrbBtnBlueMargin}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button className={styles.scrbBtnOrange}>Save</Button>
          </div>
        </Form>
      </CardBody>
    </div>
  );
};

export default UserDetail;

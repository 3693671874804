import React from 'react';
import { MdSettings, MdPieChart } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { GiPaperTray, GiHamburgerMenu } from 'react-icons/gi';
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import AccessControl from '../users/userprofile/accesscontrol';

interface NavigationProps {
  sidebarIsOpen: boolean;
  toggleSidebar: (sidebarIsOpen: boolean) => void;
  currentUsername: string;
  logout: () => void;
}

const SideNav = ({ logout, sidebarIsOpen, toggleSidebar, currentUsername }: NavigationProps) => {
  const location = useLocation();
  const mediaQuery = window.matchMedia('( min-width: 768px )');

  return (
    <div style={{ position: 'unset' }} className={sidebarIsOpen ? styles.navMaximized : styles.navMinimized}>
      <div className={styles.navListContainer}>
        <Nav vertical className="list-unstyled pb-3">
          <AccessControl permissionId={'insights'} action={'read'}>
            <div className={styles.navMenuItemContainer}>
              <NavItem className={styles.navMenuItem}>
                <NavLink className={styles.navLink} tag={Link} to={'/insights'}>
                  <MdPieChart color="#ceeaf4" size={25} className={styles.menuBtn} />
                  <div className={sidebarIsOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Data & Insights</div>
                </NavLink>
              </NavItem>
              <div
                style={{ display: location.pathname === '/insights' ? 'block' : 'none' }}
                className={sidebarIsOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
              />
            </div>
          </AccessControl>
          <AccessControl permissionId={'requests'} action={'read'}>
            <div className={styles.navMenuItemContainer}>
              <NavItem className={styles.navMenuItem}>
                <NavLink className={styles.navLink} tag={Link} to={'/requests'}>
                  <GiPaperTray color="#ceeaf4" size={25} className={styles.menuBtn} />
                  <div className={sidebarIsOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Requests</div>
                </NavLink>
              </NavItem>
              <div
                style={{ display: location.pathname === '/requests' || location.pathname === '/' ? 'block' : 'none' }}
                className={sidebarIsOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
              ></div>
            </div>
          </AccessControl>
          <AccessControl permissionId={'requestors'} action={'read'}>
            <div className={styles.navMenuItemContainer}>
              <NavItem className={styles.navMenuItem}>
                <NavLink className={styles.navLink} tag={Link} to={'/requestors'}>
                  <FaUsers color="#ceeaf4" size={25} className={styles.menuBtn} />
                  <div className={sidebarIsOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Requestors</div>
                </NavLink>
              </NavItem>
              <div
                style={{ display: location.pathname === '/requestors' ? 'block' : 'none' }}
                className={sidebarIsOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
              ></div>
            </div>
          </AccessControl>
          <div className={styles.menuDivider} />
          <AccessControl permissionId={'document'} action={'read'}>
            <div className={styles.navMenuItemContainer}>
              <NavItem className={styles.navMenuItem}>
                <NavLink className={styles.navLink} tag={Link} to={'/config'}>
                  <MdSettings color="#ceeaf4" size={25} className={styles.menuBtn} />
                  <div className={sidebarIsOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Config</div>
                </NavLink>
              </NavItem>
              <div
                style={{ display: location.pathname === '/config' ? 'block' : 'none' }}
                className={sidebarIsOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
              ></div>
            </div>
          </AccessControl>
        </Nav>
      </div>
      <div className={styles.navBarBottom}>
        <div className={styles.navMenuItemContainer} style={{ marginBottom: '1.75em' }}>
          <div className={styles.navMenuItem}>
            <GiHamburgerMenu
              color="#ceeaf4"
              size={25}
              className={styles.hamburgerIcon}
              onClick={() => {
                toggleSidebar(!sidebarIsOpen);
              }}
            />
            <div className={sidebarIsOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Show/Hide Drawer</div>
          </div>
        </div>
        <div className={styles.menuNavLinks}>
          <UncontrolledDropdown direction="up" nav inNavbar className={styles.dropdownToggle}>
            <DropdownToggle drop={'up'} nav style={{ color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '0px' }}>
              <div className={styles.tenantProfileImg}></div>
              <div className={sidebarIsOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>{currentUsername}</div>
            </DropdownToggle>
            <DropdownMenu style={{ boxShadow: '1px 2px 7px 1px rgb(0 0 0 / 13%)' }}>
              <NavLink className={styles.link} to="/userprofile">
                <DropdownItem> Profile </DropdownItem>
              </NavLink>
              <DropdownItem divider />
              <DropdownItem onClick={logout}> Log Out </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </div>
  );
};

export default SideNav;

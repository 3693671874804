import fetch from 'node-fetch';
import { TransferRequest } from '../types/transferrequests';
import { getAuthToken, getBaseApiUrl } from './helpers';
import { Aggregations } from '../types/insights';

export const searchTransferReuests = async (query: string, page: number, pageSize: number): Promise<TransferRequest[]> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requests/${encodeURI(query)}/${pageSize}/${page}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  });
  if (response.ok) {
    return response.json() as Promise<TransferRequest[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const searchTransferRequestsWithSort = async (query: string, page: number, pageSize: number, sort: string): Promise<TransferRequest[]> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requests/${encodeURI(query)}/${pageSize}/${page}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
    body: sort,
  });
  if (response.ok) {
    return response.json() as Promise<TransferRequest[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getRequestCount = async (query: string) => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requests/requestcount/${encodeURI(query)}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  }).then((response) => {
    return response.json();
  });
};

export const searchInsightsTransferRequests = async (query: string, districtId?: string): Promise<Aggregations> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requests/insights/${districtId}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
    body: query,
  });
  if (response.ok) {
    return response.json() as Promise<Aggregations>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

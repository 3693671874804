import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';

interface searchBarProps {
  updateSearchText: (value: string) => void;
  searchBarTitle: string;
  clearSearch: () => void;
}

const SearchBar = ({ updateSearchText, searchBarTitle, clearSearch }: searchBarProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');

  const onEnterKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      updateSearchText(searchValue);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    clearSearch();
  };

  const handleSubmit = () => {
    if (searchValue) {
      updateSearchText(searchValue);
    }
  };

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>{searchBarTitle}</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <Col>
                <FormGroup>
                  <Input
                    type="text"
                    id="search"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={onEnterKeyPress}
                  />
                </FormGroup>
              </Col>

              <Col md=".1">
                <Button id="clearFilterBtn" className={styles.filterBtn} onClick={handleClearSearch}>
                  <MdClear />
                </Button>
                <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
                <Button id="submitTransferRequestSearch" onClick={handleSubmit}>
                  Enter
                </Button>
                <UncontrolledTooltip target="submitTransferRequestSearch">Submit Search</UncontrolledTooltip>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchBar;

import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

interface AccessControlProps {
  children: any;
  permissionId: string;
  action: string;
}

const AccessControl = ({ children, permissionId, action }: AccessControlProps) => {
  let accessPermitted = false;
  const { user } = useContext(UserContext);
  if (user.email.includes('@scribsoft.com')) {
    return children;
  }
  if (permissionId !== 'requestors') {
    accessPermitted = true;
  }

  if (accessPermitted) {
    return children;
  } else {
    return null;
  }
};

export default AccessControl;

import { getAuthToken, getBaseApiUrl } from './helpers';
import fetch from 'node-fetch';
import { Requestors } from '../types/requestors';

export const getRequestors = async (query: string, page: number, pageSize: number, sort?: string): Promise<Requestors[]> => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requestors/${encodeURI(query)}/${pageSize}/${page}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
    },
    body: sort,
  });
  if (response.ok) {
    return response.json() as Promise<Requestors[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getRequestorsCount = async (query: string) => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requestors/count/${encodeURI(query)}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  }).then((response) => {
    return response.json();
  });
};

export const getRequestorsInsights = async () => {
  const url = `${getBaseApiUrl()}/v1/api/dashboard/requestors/insights`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
      Origin: '*',
    },
  }).then((response) => {
    return response.json();
  });
};

import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import styles from '../styles/archiveui.module.css';
import { Requestors } from '../types/requestors';
import { getRequestors, getRequestorsCount } from '../api/requestors';
import SearchBar from '../requests/requestlisttable/SearchBar';
import RequestorsTable from './requestorstable';
import { CellValue } from 'react-table';

const RequestorsList = () => {
  const [loading, setLoading] = useState(true);
  const [requestors, setRequestors] = useState<Requestors[]>([]);
  const [query, setQuery] = useState('*');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState(false);
  const [resultCount, setResultCount] = useState(10);
  const [sort, setSort] = useState('');
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Active',
        id: 'active',
        accessor: (row: any) => {
          return (new Date().getTime() - row.dateLastTransfer) / (1000 * 60 * 60 * 24) < 180 ? 'Yes' : 'No';
        },
        disableSortBy: true,
      },
      {
        Header: 'User Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'User First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Tel. Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'School',
        accessor: 'schoolName',
      },
      {
        Header: 'District',
        accessor: 'districtName',
      },
      {
        Header: 'Status',
        accessor: 'verified',
      },
      {
        Header: 'Job Duties',
        accessor: 'jobDuties',
      },
      {
        Header: 'Contact Prefer.',
        accessor: 'contactPreference',
      },
      {
        Header: 'Customer Status',
        accessor: 'transferClient',
        Cell: ({ value }: CellValue) => {
          return value ? 'Transfer Customer' : 'None';
        },
      },
      {
        Header: 'User creation',
        accessor: 'creationTime',
        Cell: ({ value }: CellValue) => {
          return new Date(value).toLocaleDateString();
        },
      },
      {
        Header: 'Date of Last Request',
        accessor: 'dateLastTransfer',
        Cell: ({ value }: CellValue) => {
          if (value) {
            return new Date(value).toLocaleDateString();
          } else {
            return '-';
          }
        },
        disableSortBy: true,
      },
      {
        Header: 'All Requests',
        accessor: 'allYearRequests',
        disableSortBy: true,
      },
    ],
    [],
  );

  useEffect(() => {
    setLoading(true);
    getRequestors(query, page, pageSize, sort).then((response) => {
      if (response.length !== undefined) {
        setRequestors(response);
        if (response.length < pageSize - 1) {
          setLastPage(true);
        } else {
          setLastPage(false);
        }
      } else {
        setRequestors([]);
      }
      setLoading(false);
    });
    getRequestorsCount(query).then((response) => {
      setResultCount(response);
    });
  }, [page, pageSize, query, sort]);

  function nextPage() {
    if (!lastPage) {
      setPage(page + 1);
    }
  }

  function previousPage() {
    if (page !== 1) {
      setPage(page - 1);
      setLastPage(false);
    }
  }

  function updateSearch(query: string) {
    if (query !== '') {
      setPage(1);
      setQuery(query);
    } else {
      setQuery('*');
    }
  }

  function clearSearchBar() {
    setQuery('*');
  }

  const handleSort = ({ sortBy }: any) => {
    sortBy = JSON.stringify(sortBy);
    if (sortBy != sort) setSort(sortBy);
  };

  return (
    <div>
      <SearchBar searchBarTitle="Users" updateSearchText={updateSearch} clearSearch={clearSearchBar} />
      <RequestorsTable columns={columns} requests={requestors} onFetchData={handleSort} loadingData={loading} />
      <div className={styles.paginationContainer}>
        <div id="resultCount" className={styles.resultPageCountContainer}>
          Page {page} of {Math.round(resultCount / pageSize)}
        </div>
        {/* <div id="resultCount">Result Count: {resultCount}</div> */}
        <Input
          className={styles.pageSizeInput}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPage(1);
            setLastPage(false);
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>
        <Button className={styles.pageBtnLeft} onClick={() => previousPage()}>
          <BsChevronDoubleLeft />
        </Button>
        <Button className={styles.pageBtn} onClick={() => nextPage()}>
          <BsChevronDoubleRight />
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginTop: '1em',
        }}
      />
    </div>
  );
};

export default RequestorsList;

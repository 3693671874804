import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Main from './main/main';
import ErrorBoundary from './errorboundary';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ErrorBoundary>
          <Main />
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
}

export default App;

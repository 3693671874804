import React, { useState } from 'react';
import moment from 'moment';
import { Table, Card, Input, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { AiFillEdit } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SearchBar from './searchbar';
import { useTable, useSortBy, usePagination } from 'react-table';
import { UncontrolledTooltip } from 'reactstrap';
import { Account } from '../../types/account';

interface AccountsListProps {
  accounts: Account[];
}

const AccountTable = ({ accounts }: AccountsListProps): JSX.Element => {
  const [searchText, updateSearchText] = useState<string>('');
  const [showInactive, updateShowInactive] = useState<boolean>(false);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created',
        accessor: 'created',
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          return <tr>{moment(props.date).format('MMM DD YYYY')}</tr>;
        },
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          return <tr>{props.actions}</tr>;
        },
      },
    ],
    [],
  );

  const applyFilter = (accounts: Account[]) => {
    return accounts.filter((account) => {
      const txtMatch =
        !searchText ||
        searchText === '' ||
        account.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0 ||
        account.shortName.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0 ||
        moment(account.created).format('MMM DD YYYY').toLowerCase().includes(searchText.trim().toLowerCase());
      const statusMatch = (showInactive && account.status === 'inactive') || (!showInactive && account.status === 'active');

      return txtMatch && statusMatch;
    });
  };

  const filteredAccounts = applyFilter(accounts);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data: accounts, initialState: { pageIndex: 0, pageSize: 25 } }, useSortBy, usePagination);

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <div>
      <div className={styles.tableContainer}>
        <SearchBar updateSearchText={updateSearchText} updateShowInactive={updateShowInactive} searchText={searchText} showInactive={showInactive} />
        <Card className={styles.tableContainer}>
          <Table striped hover size="sm" id="auditTable" responsive {...getTableProps()}>
            <thead className={styles.tableHeader}>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.Header === 'Name') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    } else if (column.Header === 'Actions') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === 'Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            className={styles.idCell}
                            onClick={() => {
                              // onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell', {
                              date: cell.row.original.created,
                              actions: (
                                <td className={styles.actionsCell}>
                                  <Link to={{ pathname: `/accountusers/${cell.row.original.tenantId}` }}>
                                    <FaUserAlt id="usersList" color="#212529" className={styles.usersIcon} />
                                    <UncontrolledTooltip target="usersList">Users</UncontrolledTooltip>
                                  </Link>
                                  <Link to={{ pathname: `/accountdetail/${cell.row.original.tenantId}` }}>
                                    <AiFillEdit id="edit" color="#212529" className={styles.pencilIcon} />
                                    <UncontrolledTooltip target="edit">Edit Account</UncontrolledTooltip>
                                  </Link>
                                </td>
                              ),
                            })}
                          </td>
                        );
                      } else if (cell.column.Header === 'Actions') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
                            onClick={() => {
                              // onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell', {
                              date: cell.row.original.created,
                              actions: (
                                <td className={styles.actionsCell} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                  <Link to={{ pathname: `/accountusers/${cell.row.original.tenantId}` }}>
                                    <FaUserAlt id="usersList" color="#212529" className={styles.usersIcon} />
                                    <UncontrolledTooltip target="usersList">Users</UncontrolledTooltip>
                                  </Link>
                                  <Link to={{ pathname: `/accountdetail/${cell.row.original.tenantId}` }}>
                                    <AiFillEdit id="edit" color="#212529" className={styles.pencilIcon} />
                                    <UncontrolledTooltip target="edit">Edit Account</UncontrolledTooltip>
                                  </Link>
                                </td>
                              ),
                            })}
                          </td>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            onClick={() => {
                              // onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell', {
                              date: cell.row.original.created,
                              actions: (
                                <td className={styles.actionsCell}>
                                  <Link to={{ pathname: `/accountusers/${cell.row.original.tenantId}` }}>
                                    <FaUserAlt id="usersList" color="#212529" className={styles.usersIcon} />
                                    <UncontrolledTooltip target="usersList">Users</UncontrolledTooltip>
                                  </Link>
                                  <Link to={{ pathname: `/accountdetail/${cell.row.original.tenantId}` }}>
                                    <AiFillEdit id="edit" color="#212529" className={styles.pencilIcon} />
                                    <UncontrolledTooltip target="edit">Edit Account</UncontrolledTooltip>
                                  </Link>
                                </td>
                              ),
                            })}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </div>
      <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
        <div style={{ marginRight: '.5em' }}>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Button>{' '}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </Button>{' '}
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </Button>{' '}
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Button>{' '}
        </div>
        <div style={{ marginRight: '.5em' }}>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
        <Input
          style={{ maxWidth: '10em' }}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};

export default AccountTable;

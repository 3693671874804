import React, { ChangeEvent } from 'react';
import { ListGroupItem, Input, Row, Col, Label, FormGroup, ListGroupItemText, ListGroupItemHeading, Button } from 'reactstrap';
import { DefinedPermissionActions, Permission, PermissionActions } from '../../types/role';
import styles from '../../styles/archiveui.module.css';

interface PermissionProps {
  permission: Permission;
  allowedActions: PermissionActions | null;
  actionsFromDefinititon: DefinedPermissionActions;
  togglePermission: (event: ChangeEvent<HTMLInputElement>, perm: Permission) => void;
  toggleAll: (perm: Permission) => void;
}

const RolePermission = ({ permission, allowedActions, togglePermission, actionsFromDefinititon, toggleAll }: PermissionProps) => {
  return (
    <ListGroupItem className={styles.rolePermissionContainer}>
      <Col form>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>
            <ListGroupItemHeading>{permission.name}</ListGroupItemHeading>
            <ListGroupItemText>{permission.description}</ListGroupItemText>
          </div>
          <FormGroup check>
            <Label check>
              <Button onClick={() => toggleAll(permission)} size="sm" className={styles.scrbBtnOrange}>
                {' '}
                Allow All{' '}
              </Button>
            </Label>
          </FormGroup>
        </Row>
        <Row>
          <Col sm="3" style={actionsFromDefinititon.create ? {} : { display: 'none' }}>
            <FormGroup row>
              <Label sm="3">Create:</Label>
              <Col sm="9">
                <Input
                  value={allowedActions?.create || 'NONE'}
                  type="select"
                  name="create"
                  id="type"
                  required={true}
                  onChange={(e) => {
                    togglePermission(e, permission);
                  }}
                  disabled={!actionsFromDefinititon.create}
                >
                  <option value="NONE">Not Set</option>
                  <option value="ALLOW">Allow</option>
                  <option value="DENY">Deny</option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col sm="3" style={actionsFromDefinititon.read ? {} : { display: 'none' }}>
            <FormGroup row>
              <Label sm="3">Read</Label>
              <Col sm="9">
                <Input
                  value={allowedActions?.read || 'NONE'}
                  type="select"
                  name="read"
                  id="type"
                  required={true}
                  onChange={(e) => togglePermission(e, permission)}
                  disabled={!actionsFromDefinititon.read}
                >
                  <option value="NONE">Not Set</option>
                  <option value="ALLOW">Allow</option>
                  <option value="DENY">Deny</option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col sm="3" style={actionsFromDefinititon.update ? {} : { display: 'none' }}>
            <FormGroup row>
              <Label sm="3">Update</Label>
              <Col sm="9">
                <Input
                  value={allowedActions?.update || 'NONE'}
                  type="select"
                  name="update"
                  id="type"
                  required={true}
                  disabled={!actionsFromDefinititon.update}
                  onChange={(e) => togglePermission(e, permission)}
                >
                  <option value="NONE">Not Set</option>
                  <option value="ALLOW">Allow</option>
                  <option value="DENY">Deny</option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col sm="3" style={actionsFromDefinititon.delete ? {} : { display: 'none' }}>
            <FormGroup row>
              <Label sm="3">Delete</Label>
              <Col sm="9">
                <Input
                  value={allowedActions?.delete || 'NONE'}
                  type="select"
                  name="delete"
                  id="type"
                  required={true}
                  onChange={(e) => togglePermission(e, permission)}
                  disabled={!actionsFromDefinititon.delete}
                >
                  <option value="NONE">Not Set</option>
                  <option value="ALLOW">Allow</option>
                  <option value="DENY">Deny</option>
                </Input>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </ListGroupItem>
  );
};

export default RolePermission;

import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import DisplaySettingsTable from './displaysettings/table';
import SchoolYearSettingsTable from './schoolyearsettings/table';
import EnrollmentSettingsTable from './enrollmentsettings/table';
import { toast } from 'react-toastify';
import { saveDistrictConfigSettings, saveUserConfigSettings } from '../api/configsettings';
import { DistrictConfigSettings, UserConfigSettings } from '../types/configsettings';
import { UserContext } from '../contexts/UserContext';

interface ModalProps {
  userSettings: UserConfigSettings;
  districtSettings: DistrictConfigSettings;
  modalState: boolean;
  setModalState: (value: boolean) => void;
  setUpdatedConfigSettings: (userConfigSettings: UserConfigSettings) => void;
}

const ConfigModal = ({ userSettings, districtSettings, modalState, setModalState, setUpdatedConfigSettings }: ModalProps) => {
  // Toggle for Modal
  const toggleModal = () => setModalState(!modalState);
  const user = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('1');

  const [hideSaveSchoolYearsButton, setHideSaveSchoolYearsButton] = useState(false);
  const [userConfigSettings, setUserConfigSettings] = useState<UserConfigSettings>(userSettings);
  const [districtConfigSettings, setDistrictConfigSettings] = useState<DistrictConfigSettings>(districtSettings);

  const updateDisplaySettings = (settingName: string, setting: boolean) => {
    if (userConfigSettings != undefined) {
      const displaySettingsCopy = [...userConfigSettings.displaySettings];
      for (const obj of displaySettingsCopy) {
        if (obj.displaySetting === settingName) {
          obj.show = setting;
        }
      }
      setUserConfigSettings((prevValues) => {
        return { ...prevValues, displaySettings: displaySettingsCopy };
      });
    }
  };

  const updateSchoolYearSettings = (id: string, columnId: string, value: string | boolean) => {
    const schoolYearsSettingsCopy = [...districtConfigSettings.schoolYearsSettings];
    for (const obj of schoolYearsSettingsCopy) {
      if (obj.id === id) {
        (obj as any)[columnId] = value;
      }
    }
    setDistrictConfigSettings((prevValues) => {
      return { ...prevValues, schoolYearsSettings: schoolYearsSettingsCopy };
    });
  };

  const updateEnrollementSettings = (settingName: string, setting: boolean) => {
    if (userConfigSettings) {
      const enrollmentSettingsCopy = [...userConfigSettings.enrollmentSettings];
      for (const obj of enrollmentSettingsCopy) {
        if (obj.enrollmentSetting === settingName) {
          obj.show = setting;
        }
      }
      setUserConfigSettings((prevValues) => {
        return { ...prevValues, enrollmentSettings: enrollmentSettingsCopy };
      });
    }
  };

  useEffect(() => {
    setUserConfigSettings(userSettings);
    setDistrictConfigSettings(districtSettings);
  }, [userSettings, districtSettings]);

  const updateUserConfigSettings = async (event: any) => {
    event.preventDefault();
    // need to see how to do this... probably cannot just use 'false'
    let newConfigSettings = false;
    if (activeTab == '2') {
      if (districtSettings.districtId == '') {
        districtSettings.districtId = user.user.districtId;
        newConfigSettings = true;
      }
      const response = await toast.promise(saveDistrictConfigSettings(user.user.districtId, districtSettings, newConfigSettings), {
        pending: 'Updating Settings',
        success: 'Settings Updated',
        error: 'There was an error',
      });
      if (response === 200) {
        toggleModal();
        setUpdatedConfigSettings(userSettings);
        return response;
      }
      return response;
    } else {
      if (userSettings.userId == '0') {
        userSettings.userId = user.user.id;
        newConfigSettings = true;
      }
      const response = await toast.promise(saveUserConfigSettings(user.user.id, userSettings, newConfigSettings), {
        pending: 'Updating Settings',
        success: 'Settings Updated',
        error: 'There was an error',
      });
      if (response === 200) {
        toggleModal();
        setUpdatedConfigSettings(userSettings);
        return response;
      }
      return response;
    }
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
    const userScope = user.user.scope.name;
    const hide = tab === '2' && userScope !== 'DISTRICT' && userScope !== 'ALL';
    setHideSaveSchoolYearsButton(hide);
  };

  return (
    <Modal toggle={toggleModal} isOpen={modalState} size="xl">
      <ModalHeader toggle={toggleModal}>Configuration Settings</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="6" sm="6" md="2">
            <Nav vertical pills style={{ marginTop: '1em' }}>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? styles.activeTab : styles.nonActiveTab}
                  active={activeTab === '1'}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Display
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '2' ? styles.activeTab : styles.nonActiveTab}
                  active={activeTab === '2'}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  School Years
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '3' ? styles.activeTab : styles.nonActiveTab}
                  active={activeTab === '3'}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  Enrollment
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="6" sm="6" md="10">
            <TabContent activeTab={activeTab} style={{ marginTop: '1em', padding: '1em 2em' }}>
              <TabPane tabId="1">
                <DisplaySettingsTable
                  displaySettings={userConfigSettings ? userConfigSettings.displaySettings : []}
                  setDisplaySettingsState={updateDisplaySettings}
                />
              </TabPane>
              <TabPane tabId="2">
                <SchoolYearSettingsTable
                  schoolYearSettings={districtConfigSettings ? districtConfigSettings.schoolYearsSettings : []}
                  setSchoolYearSettingsState={updateSchoolYearSettings}
                />
              </TabPane>
              <TabPane tabId="3">
                <EnrollmentSettingsTable
                  enrollmentSettings={userConfigSettings ? userConfigSettings.enrollmentSettings : []}
                  setEnrollmentSettingsState={updateEnrollementSettings}
                />
              </TabPane>
            </TabContent>
            {!hideSaveSchoolYearsButton && (
              <div className={styles.configSettingsBtnContainer}>
                <Button onClick={updateUserConfigSettings} color="primary">
                  Save Settings
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ConfigModal;

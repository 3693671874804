import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { fetchDistrict, fetchDistrictsByState, fetchSchoolById, fetchSchoolsByDistrict } from '../../api/schooldistrict';
import States from '../../data/states';
import { TreeSelect, TreeSelectProps } from 'antd';
import 'antd/dist/antd.css';

interface Props {
  scope: string;
  scopeId: string;
  selectedDistrictSchoolId: string;
  handleDropdownOnChange?: (values: any) => void;
}

const SchoolDistrictDropdown = ({ scope, scopeId, selectedDistrictSchoolId, handleDropdownOnChange }: Props) => {
  const statesData = States;
  const [treeData, setTreeData] = useState([]);
  const [value, setValue] = useState<string>();
  const [placeholder, setPlaceholder] = useState<string>();
  const [loading, setLoading] = useState(true);
  const treeSelect = React.createRef<any>();

  const onChange = (newValue: string) => {
    setValue(newValue);
    if (handleDropdownOnChange) {
      handleDropdownOnChange(newValue);
    }
    if (treeSelect && treeSelect.current) {
      treeSelect.current.blur();
    }
  };

  const onLoadData: TreeSelectProps['loadData'] = async ({ value }) => {
    if (value) {
      const nodes: any = [];
      const nodeId = value.toString();
      if (nodeId.length == 2) {
        const response = await fetchDistrictsByState(nodeId);
        const districts = JSON.parse(response);
        for (const district of districts) {
          const node = {
            id: district.id,
            value: district.id,
            title: district.name,
            pId: value,
          };
          nodes.push(node);
        }
      } else if (nodeId.length === 7) {
        //Fetch schools
        const response = await fetchSchoolsByDistrict(nodeId);
        const schools = JSON.parse(response);
        for (const school of schools) {
          const node = {
            id: school.id,
            value: school.id,
            title: school.name,
            pId: value,
            isLeaf: true,
          };
          nodes.push(node);
        }
      }
      setTreeData(treeData.concat(nodes));
    }
  };

  const updateData = async () => {
    const treeNodes: any = [];
    if (scope === 'ALL') {
      setPlaceholder('Please select district/school');
      statesData.forEach((state) => {
        const node = {
          id: state.abbreviation,
          value: state.abbreviation,
          title: state.name,
          selectable: false,
        };
        treeNodes.push(node);
      });
    } else {
      setPlaceholder('Loading...');
      if (scope === 'DISTRICT') {
        const district = JSON.parse(await fetchDistrict(scopeId));
        const currentDistrictId = district.id;
        const parentNode = {
          id: currentDistrictId,
          title: district.name,
          value: currentDistrictId,
        };
        treeNodes.push(parentNode);
      } else {
        const school = JSON.parse(await fetchSchoolById(scopeId));
        const node = {
          id: school.id,
          title: school.name,
          value: school.id,
          isLeaf: true,
        };
        treeNodes.push(node);
      }
      setValue(selectedDistrictSchoolId);
    }
    setTreeData(treeNodes);
    setLoading(false);
  };

  useEffect(() => {
    updateData();
  }, [scopeId]);

  return (
    <>
      <Row>
        <Col>
          <h6>Select your School or District:</h6>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: '1em' }}>
          <TreeSelect
            ref={treeSelect}
            treeDataSimpleMode
            disabled={loading}
            style={{ width: '60%' }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder={placeholder}
            onChange={onChange}
            loadData={onLoadData}
            treeData={treeData}
            treeNodeFilterProp="title"
            showSearch
          />
        </Col>
      </Row>
    </>
  );
};

export default SchoolDistrictDropdown;

import React from 'react';
import { Col, Row } from 'reactstrap';
import DataView from './dataview';
import { DistrictConfigSettings, UserConfigSettings } from '../types/configsettings';

interface Props {
  userConfigSettings: UserConfigSettings;
  districtConfigSettings: DistrictConfigSettings;
}

const Config = ({ userConfigSettings, districtConfigSettings }: Props) => {
  return (
    <div>
      {' '}
      <Row>
        <Col sm="5" md={{ offset: 3, size: 5 }}>
          <DataView
            configSettings={userConfigSettings}
            districtSettings={districtConfigSettings}
            selectedSchoolYearId={''}
            selectedSchoolDistrictId={''}
            handleChangeYear={(value?: string) => {
              console.log('CONFIG', value);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Config;

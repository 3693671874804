import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useHistory } from 'react-router-dom';
import { Loading } from '../Loading';
import { getRequestorsInsights } from '../api/requestors';
import { AggregationBucket } from '../types/insights';
import { UserContext } from '../contexts/UserContext';

const UserData = () => {
  const userProfile = React.useContext(UserContext);
  const userData = userProfile.user;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [totalUsersData, setTotalUsersData] = useState<AggregationBucket[]>([]);
  const [totalUserCustomerData, setTotalUserCustomerData] = useState<AggregationBucket[]>([]);

  const handleTotalChartClick = () => {
    return history.push('/requestors');
  };

  const processChartData = (response: AggregationBucket[]) => {
    const totalUsers: AggregationBucket[] = [];
    const totalUsersCustomers: AggregationBucket[] = [];
    Object.entries(response).map(([key, value]) => {
      if (key == 'active' || key == 'inactive') {
        if (value.doc_count != undefined) {
          totalUsers.push({ key: key, doc_count: value.doc_count });
        }
      } else {
        if (value.doc_count != undefined) {
          totalUsersCustomers.push({ key: key, doc_count: value.doc_count });
        }
      }
    });
    setTotalUsersData(totalUsers);
    setTotalUserCustomerData(totalUsersCustomers);
  };

  const customChartLabels: { [key: string]: { label: string; color: string } } = {
    //total users chart labels
    active: { label: 'Active', color: '#F05934' },
    inactive: { label: 'Inactive', color: '#F69E89' },
    //total users vs customers chart labels
    client: { label: 'Customers|w/Transfer', color: '#123E75' },
    nonclient: { label: 'Non-Customers', color: '#62C2EE' },
  };

  const renderCustomLabel = ({ payload, x, y, cx, name, value, percent }: any) => {
    const label = customChartLabels[name].label;
    const tspans = label.split('|').map((value, index) => (
      <tspan key={index} x={x > cx ? x + 5 : x - 5} dy={index + 14}>
        {value}
      </tspan>
    ));
    return (
      <text
        x={x > cx ? x + 5 : x - 5}
        y={y - 15}
        fill={payload.fill}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="text-top"
        style={{ fontSize: 12 }}
      >
        {tspans}
        <tspan x={x > cx ? x + 5 : x - 5} dy="15" style={{ fontSize: 12, fill: '#999' }}>
          {`${value.toLocaleString()} (${(percent * 100).toFixed(2)}%)`}
        </tspan>
      </text>
    );
  };

  useEffect(() => {
    setLoading(true);
    getRequestorsInsights().then((response) => {
      if (response !== undefined) {
        processChartData(response);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div>
      {!userData.email.includes('@scribsoft.com') && (
        <div>
          <Col md="4">
            <Card outline color="secondary" style={{ height: 200 }}>
              <CardBody>
                <h5>USER DATA</h5>
                <p>Coming Soon...</p>
              </CardBody>
            </Card>
          </Col>
        </div>
      )}
      {userData.email.includes('@scribsoft.com') && (
        <div>
          {loading && <Loading />}
          {!loading && (
            <Row>
              <Col sm="12" md="6">
                <h5 className={styles.chartTitle}>TOTAL USERS</h5>
                <ResponsiveContainer width="100%" height={350}>
                  <PieChart width={200} height={200} onClick={handleTotalChartClick} style={{ cursor: 'pointer' }}>
                    <Pie
                      data={totalUsersData}
                      dataKey="doc_count"
                      nameKey="key"
                      cx="50%"
                      cy="50%"
                      outerRadius={70}
                      fill="#8884d8"
                      paddingAngle={2}
                      minAngle={20}
                      label={renderCustomLabel}
                      isAnimationActive={false}
                    >
                      {totalUsersData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={customChartLabels[entry.key].color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Col>
              <Col md="6">
                <h5 className={styles.chartTitle}>TOTAL USERS VS CUSTOMERS</h5>
                <ResponsiveContainer width="100%" height={350}>
                  <PieChart width={200} height={200} onClick={handleTotalChartClick} style={{ cursor: 'pointer' }}>
                    <Pie
                      data={totalUserCustomerData}
                      dataKey="doc_count"
                      nameKey="key"
                      cx="50%"
                      cy="50%"
                      outerRadius={70}
                      fill="#8884d8"
                      paddingAngle={2}
                      minAngle={20}
                      label={renderCustomLabel}
                      isAnimationActive={false}
                    >
                      {totalUserCustomerData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={customChartLabels[entry.key].color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default UserData;

import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useAsyncDebounce, CellValue } from 'react-table';
import { Table } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import { Requestors } from '../types/requestors';
import { Bars } from 'react-loader-spinner';

interface RequestTableProps {
  columns: [];
  loadingData: boolean;
  requests: Requestors[];
  onFetchData: (value: any) => void;
}

const RequestorsTable = ({ columns, requests, onFetchData, loadingData }: RequestTableProps) => {
  const [loading, setLoading] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns,
      data: requests,
      initialState: { pageIndex: 0, sortBy: [{ id: 'lastName', desc: false }] },
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100);

  useEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters });
    setLoading(loadingData);
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, filters, loadingData]);

  return (
    <div className={styles.tableContainer}>
      <Table striped bordered hover size="sm" responsive style={{ backgroundColor: 'white', marginTop: '1em' }} {...getTableProps()}>
        <thead className={styles.tableHeader}>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.thRequests}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading && (
            <tr>
              <td colSpan={columns.length}>
                <Bars height="36" color="#2582b5" wrapperStyle={{ justifyContent: 'center' }} />
              </td>
            </tr>
          )}
          {!loading &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.Header === 'Active') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td>{cell.render('Cell')}</td>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default RequestorsTable;

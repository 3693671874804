import React, { useEffect, useState } from 'react';
import AuditTable from '../auditlogtable/auditTable';
import { getAudits } from '../../api/audits';
import styles from '../../styles/archiveui.module.css';
import { withRouter } from 'react-router';
import { Loading } from '../../Loading';
import { AuditEntry } from '../../types/audit';

const AuditLog = (): JSX.Element => {
  const [audits, updateAudits] = useState<AuditEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const auditsPerPage = 5;
  const [actorFilter, updateActorFilter] = useState<string>('');
  const [targetFilter, updateTargetFilter] = useState<string>('');
  const [resultFilter, updateResultFilter] = useState<string>('');
  const [applicationFilter, updateApplicationFilter] = useState<string>('');
  const [startDate, updateStartDate] = useState<string>('');
  const [endDate, updateEndDate] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const fetchAudits = async () => {
    const audits = await getAudits(actorFilter, targetFilter, resultFilter, applicationFilter, startDate, endDate);
    updateAudits(audits);
  };

  useEffect(() => {
    fetchAudits();
    setLoading(false);
  }, []);

  const indexOfLastAudit = currentPage * auditsPerPage;
  const indexOfFirstAccount = indexOfLastAudit - auditsPerPage;
  const currentAudits = audits.slice(indexOfFirstAccount, indexOfLastAudit);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={styles.customLogContainer}>
      {loading && <Loading />}
      {!loading && (
        <AuditTable
          audits={currentAudits}
          updateActorFilter={updateActorFilter}
          updateTargetFilter={updateTargetFilter}
          updateResultFilter={updateResultFilter}
          updateApplicationFilter={updateApplicationFilter}
          updateStartDate={updateStartDate}
          updateEndDate={updateEndDate}
          actorFilter={actorFilter}
          targetFilter={targetFilter}
          resultFilter={resultFilter}
          applicationFilter={applicationFilter}
          startDate={startDate}
          endDate={endDate}
          searchAudits={fetchAudits}
        />
      )}
    </div>
  );
};

export default withRouter(AuditLog);

import React, { useEffect, useState } from 'react';
import RequestTable from '../requestlisttable/requesttable';
import { getRequestCount, searchTransferRequestsWithSort } from '../../api/transferrequests';
import { TransferRequest } from '../../types/transferrequests';
import SearchBar from '../requestlisttable/SearchBar';
import { Button, Input } from 'reactstrap';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import styles from '../../styles/archiveui.module.css';
import { useLocation } from 'react-router-dom';

const RequestsList = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [transferrequests, setTransferRequests] = useState<TransferRequest[]>([]);
  const [query, setQuery] = useState('*');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState(false);
  const [resultCount, setResultCount] = useState(10);
  const [sort, setSort] = useState('');

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Order ID',
        accessor: 'orderId', // accessor is the "key" in the data
      },
      {
        Header: 'First Name',
        accessor: 'studentFirstName',
      },
      {
        Header: 'Middle Name',
        accessor: 'studentMiddleName',
      },
      {
        Header: 'Last Name',
        accessor: 'studentLastName',
      },
      {
        Header: 'Prev. School',
        accessor: 'lastSchoolAttendedSchoolName',
      },
      {
        Header: 'Prev. District',
        accessor: 'lastSchoolAttendedDistrictName',
      },
      {
        Header: 'Grade',
        accessor: 'currentGrade',
      },
      {
        Header: 'Transfer Date',
        accessor: 'transferDate',
      },
      {
        Header: 'Dest. School',
        accessor: 'destinationSchoolName',
      },
      {
        Header: 'Dest. District',
        accessor: 'destinationDistrictName',
      },
      {
        Header: 'School Type',
        accessor: 'programType',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Comments',
        accessor: 'requestorComments',
      },
      {
        Header: 'Request Date',
        accessor: 'creationDate',
      },
    ],
    [],
  );

  useEffect(() => {
    setLoading(true);
    let customQuery = query;
    if (location.state !== undefined) {
      customQuery = (location.state as any).query;
      setQuery(customQuery.toLowerCase());
    }
    searchTransferRequestsWithSort(customQuery, page, pageSize, sort).then((response) => {
      if (response.length !== undefined) {
        setTransferRequests(response);
        if (response.length < pageSize - 1) {
          setLastPage(true);
        } else {
          setLastPage(false);
        }
      } else {
        setTransferRequests([]);
      }
      setLoading(false);
    });
    getRequestCount(customQuery).then((response) => {
      setResultCount(response);
    });
  }, [page, pageSize, query, sort]);

  function nextPage() {
    if (!lastPage) {
      setPage(page + 1);
    }
  }

  function previousPage() {
    if (page !== 1) {
      setPage(page - 1);
      setLastPage(false);
    }
  }

  function updateSearch(query: string) {
    if (query !== '') {
      setPage(1);
      setQuery(query);
    } else {
      setQuery('*');
    }
  }

  function clearSearchBar() {
    setQuery('*');
  }

  const handleSort = ({ sortBy }: any) => {
    sortBy = JSON.stringify(sortBy);
    if (sortBy != sort) setSort(sortBy);
  };

  return (
    <div>
      <SearchBar searchBarTitle="Requests" updateSearchText={updateSearch} clearSearch={clearSearchBar} />
      <RequestTable columns={columns} requests={transferrequests} onSort={handleSort} loadingData={loading} />
      <div className={styles.paginationContainer}>
        <div id="resultCount" className={styles.resultPageCountContainer}>
          Page {page} of {Math.round(resultCount / pageSize)}
        </div>
        {/* <div id="resultCount">Result Count: {resultCount}</div> */}
        <Input
          className={styles.pageSizeInput}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPage(1);
            setLastPage(false);
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Input>
        <Button className={styles.pageBtnLeft} onClick={() => previousPage()}>
          <BsChevronDoubleLeft />
        </Button>
        <Button className={styles.pageBtn} onClick={() => nextPage()}>
          <BsChevronDoubleRight />
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginTop: '1em',
        }}
      />
    </div>
  );
};

export default RequestsList;

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import DataView from '../config/dataview';
import { DistrictConfigSettings, UserConfigSettings } from '../types/configsettings';
import { searchInsightsTransferRequests } from '../api/transferrequests';
import { Loading } from '../Loading';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { SchoolYears } from '../types/schoolyears';
import styles from '../styles/archiveui.module.css';
import { AggregationBucket, Aggregations } from '../types/insights';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import UserData from './userdata';

interface Props {
  userConfigSettings: UserConfigSettings;
  districtConfigSettings: DistrictConfigSettings;
}

const Insights = ({ userConfigSettings, districtConfigSettings }: Props) => {
  const userProfile = React.useContext(UserContext);
  const userData = userProfile.user;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const schoolYearsList = districtConfigSettings.schoolYearsSettings;
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<string>('');
  const [selectedSchoolDistrictId, setSelectedSchoolDistrictId] = useState<string>(userData.scope.value);
  const [statusData, setStatusData] = useState<AggregationBucket[]>([]);
  const [programTypeData, setProgramTypeData] = useState<AggregationBucket[]>([]);
  const [locationData, setLocationData] = useState<AggregationBucket[]>([]);
  const [yearLabel, setYearLabel] = useState('All years');
  const [query, setSearchQuery] = useState('*');
  const [programTypeQuery, setProgramTypeQuery] = useState('*');
  const [locationQuery, setLocationQuery] = useState('*');
  const handleSYChange = (value?: string) => {
    if (value !== undefined) {
      setSelectedSchoolYear(value);
    }
  };

  const customChartLabels: { [key: string]: { label: string; color: string } } = {
    //status chart labels
    approve: { label: 'Approved', color: '#F05934' },
    deny: { label: 'Denied', color: '#F69E89' },
    submitted: { label: 'Submitted', color: '#F48267' },
    open: { label: 'Open', color: '#FFC824' },
    error: { label: 'Error', color: '#FFDA6D' },
    //location chart labels
    inDistrict: { label: 'In-District', color: '#FABB83' },
    inState: { label: 'In-State', color: '#F68B2B' },
    outState: { label: 'Out of State', color: '#F79E4E' },
    //type chart labels
    virtual: { label: 'Virtual', color: '#9DD9F5' },
    charter: { label: 'Charter', color: '#13A4E6' },
    choice: { label: 'Choice', color: '#617EA3' },
    magnet: { label: 'Magnet', color: '#9CAFC5' },
    traditional: { label: 'Traditional', color: '#123E75' },
    other: { label: 'Other', color: '#62C2EE' },
    private: { label: 'Private', color: '#99E3E6' },
  };

  const handleDistrictSchoolChange = (selectedItemValue: string) => {
    setSelectedSchoolDistrictId(selectedItemValue);
  };

  const processStatusChart = (response: AggregationBucket[]) => {
    setStatusData(response);
  };

  const processProgramTypeChart = (response: AggregationBucket[]) => {
    setProgramTypeData(response);
  };

  const processLocationChart = (response: Aggregations) => {
    const locations: AggregationBucket[] = [];
    Object.entries(response).map(([key, value]) => {
      if (value.doc_count != undefined) {
        locations.push({ key: key, doc_count: value.doc_count });
      }
    });
    setLocationData(locations);
  };

  const renderCustomLabel = ({ payload, x, y, cx, name, value, percent }: any) => {
    return (
      <text x={x > cx ? x + 5 : x - 5} y={y} fill={payload.fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="text-top" style={{ fontSize: 14 }}>
        <tspan x={x > cx ? x + 5 : x - 5}>{`${customChartLabels[name].label}`}</tspan>
        <tspan x={x > cx ? x + 5 : x - 5} dy="15" style={{ fontSize: 12, fill: '#999' }}>
          {`${value.toLocaleString()} (${(percent * 100).toFixed(2)}%)`}
        </tspan>
      </text>
    );
  };

  const handleStatusChartClick = () => {
    return history.push('/requests', { query: query });
  };

  const handleProgramTypeChartClick = () => {
    return history.push('/requests', { query: programTypeQuery });
  };

  const handleLocationChartClick = () => {
    return history.push('/requests', { query: locationQuery });
  };

  useEffect(() => {
    setLoading(true);
    let districtId = '';
    let schoolYearQuery = '';
    let schoolDistrictQuery = '*';
    let fieldToMatch: string;
    if (userData.scope.value != 'ALL') {
      districtId = userData.districtId;
    }
    if (selectedSchoolDistrictId.length === 7) {
      fieldToMatch = 'lastSchoolAttendedDistrictId';
    } else {
      fieldToMatch = 'lastSchoolAttendedSchoolId';
    }
    if (selectedSchoolDistrictId != userData.scope.value || userData.scope.name != 'ALL') {
      schoolDistrictQuery = `${fieldToMatch}:${selectedSchoolDistrictId}`;
    }
    const programTypeQuery = `status:approve`;
    const locationQuery = `_exists_:destinationSchoolState`;
    if (selectedSchoolYear !== '' && selectedSchoolYear !== 'All') {
      const schoolYear = schoolYearsList.find((obj: SchoolYears | undefined) => obj?.id === selectedSchoolYear);
      if (schoolYear !== undefined) {
        if (schoolYear.current) {
          setYearLabel('Current year');
        } else {
          setYearLabel(schoolYear.name);
        }
        const startDate = Date.parse(schoolYear.startDate);
        const endDate = Date.parse(schoolYear.endDate);
        schoolYearQuery = `creationDate:[${startDate} TO ${endDate}]`;
      }
    }
    if (schoolYearQuery.length !== 0) {
      schoolYearQuery = ' AND ' + schoolYearQuery;
    }
    setSearchQuery(schoolDistrictQuery + schoolYearQuery);
    setProgramTypeQuery(schoolDistrictQuery + ' AND ' + programTypeQuery + schoolYearQuery);
    setLocationQuery(schoolDistrictQuery + ' AND ' + locationQuery + ' AND ' + programTypeQuery + schoolYearQuery);
    searchInsightsTransferRequests(schoolDistrictQuery + schoolYearQuery, districtId).then((response) => {
      if (response !== undefined) {
        processStatusChart(response.status.buckets);
        searchInsightsTransferRequests(schoolDistrictQuery + ' AND ' + programTypeQuery + schoolYearQuery, districtId).then((res) => {
          processProgramTypeChart(res.programType.buckets);
          searchInsightsTransferRequests(schoolDistrictQuery + ' AND ' + locationQuery + ' AND ' + programTypeQuery + schoolYearQuery, districtId).then(
            (locationResponse) => {
              processLocationChart(locationResponse);
              setLoading(false);
            },
          );
        });
      } else {
        //Tell the user there was an error and couldn't get the requests
      }
    });
  }, [selectedSchoolYear, selectedSchoolDistrictId]);

  return (
    <div>
      <Row>
        <Col md="8">
          <UserData />
        </Col>
        <Col sm="5" md="4">
          <DataView
            configSettings={userConfigSettings}
            districtSettings={districtConfigSettings}
            selectedSchoolYearId={selectedSchoolYear}
            selectedSchoolDistrictId={selectedSchoolDistrictId}
            handleChangeYear={handleSYChange}
            handleChangeSchoolDistrict={handleDistrictSchoolChange}
          />
        </Col>
      </Row>
      {loading && <Loading />}
      {!loading && (
        <div>
          <Row style={{ margin: '1em 0' }}>
            <Col>
              <h5>Transfer Requests</h5>
            </Col>
          </Row>
          <Row style={{ margin: '1em 0' }}>
            <Col md="4">
              <h5 className={styles.chartTitle}>
                {yearLabel} Transfer requests
                <br />
                (outbound - by status)
              </h5>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart width={400} height={400} onClick={handleStatusChartClick} style={{ cursor: 'pointer' }}>
                  <Pie
                    data={statusData}
                    dataKey="doc_count"
                    nameKey="key"
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#8884d8"
                    paddingAngle={2}
                    minAngle={20}
                    label={renderCustomLabel}
                  >
                    {statusData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={customChartLabels[entry.key].color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col md="4">
              <h5 className={styles.chartTitle}>
                {yearLabel} Transfer requests
                <br />
                (outbound - by location)
              </h5>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart width={400} height={400} onClick={handleLocationChartClick} style={{ cursor: 'pointer' }}>
                  <Pie
                    data={locationData}
                    dataKey="doc_count"
                    nameKey="key"
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#8884d8"
                    paddingAngle={2}
                    minAngle={20}
                    label={renderCustomLabel}
                  >
                    {locationData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={customChartLabels[entry.key].color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col md="4">
              <h5 className={styles.chartTitle}>
                {yearLabel} Transfer requests
                <br />
                (outbound - by type)
              </h5>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart width={400} height={400} onClick={handleProgramTypeChartClick} style={{ cursor: 'pointer' }}>
                  <Pie
                    data={programTypeData}
                    dataKey="doc_count"
                    nameKey="key"
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#8884d8"
                    paddingAngle={2}
                    minAngle={20}
                    label={renderCustomLabel}
                  >
                    {programTypeData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={customChartLabels[entry.key].color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Col>
          </Row>
          <Row style={{ margin: '1em 0' }}>
            <Col>
              <h5>Enrollment & Funding</h5>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card outline color="secondary" style={{ height: 300 }}>
                <CardBody>
                  <h5>ENROLLMENT DATA</h5>
                  <p>Coming Soon...</p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card outline color="secondary" style={{ height: 300 }}>
                <CardBody>
                  <h5>FUNDING REVENUE DATA</h5>
                  <p>Coming Soon...</p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card outline color="secondary" style={{ height: 300 }}>
                <CardBody>
                  <h5>GRAD RATES DATA</h5>
                  <p>Coming Soon...</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Insights;

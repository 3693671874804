/* eslint-disable react/jsx-key */
import React, { useContext } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Button, Col, Row, Table } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { SchoolYears } from '../../types/schoolyears';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../contexts/UserContext';

interface SchoolYearSettingsTableProps {
  schoolYearSettings: SchoolYears[];
  setSchoolYearSettingsState: (id: string, columnId: string, value: string) => void;
}

const SchoolYearSettingsTable = ({ schoolYearSettings, setSchoolYearSettingsState }: SchoolYearSettingsTableProps) => {
  const user = useContext(UserContext);

  const showAddButton = user.user.scope.name == 'DISTRICT' || user.user.scope.name == 'ALL';
  // Create an editable cell renderer
  const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }: any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    const handleCheckboxClick = (event: React.MouseEvent<HTMLInputElement>) => {
      (event.target as HTMLButtonElement).focus();
    };

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.checked);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (id === 'current' || id === 'display') {
      return (
        <input
          type="checkbox"
          defaultChecked={value === 'Yes'}
          onChange={handleCheck}
          onBlur={onBlur}
          onClick={handleCheckboxClick}
          disabled={!showAddButton}
        />
      );
    } else if (id == 'startDate' || id == 'endDate') {
      return <input type="date" value={value} onChange={onChange} onBlur={onBlur} disabled={!showAddButton} />;
    } else {
      return <input value={value} onChange={onChange} onBlur={onBlur} disabled={!showAddButton} />;
    }
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell,
  };

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'School Year',
        accessor: 'name',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
      },
      {
        id: 'current',
        Header: 'Current',
        accessor: (row: SchoolYears) => {
          return row.current ? 'Yes' : 'No';
        },
      },
      {
        id: 'display',
        Header: 'Display',
        accessor: (row: SchoolYears) => {
          return row.display ? 'Yes' : 'No';
        },
      },
    ],
    [],
  );

  const [data, setData] = React.useState(() => schoolYearSettings);
  //const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex: number, columnId: string, value: string) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          setSchoolYearSettingsState(row.id, columnId, value);
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
    setData(schoolYearSettings);
  }, [schoolYearSettings]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  //const resetData = () => setData(originalData);

  const newRow: SchoolYears = {
    id: uuidv4(),
    name: '',
    startDate: '',
    endDate: '',
    current: false,
    display: false,
  };

  const createNewRow = () => {
    setSkipPageReset(true);
    data.push(newRow);
    setData(data);
    updateMyData(-1, '', '');
  };

  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, rows } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useSortBy,
    usePagination,
  );

  const AddButton = () => (
    <Button onClick={createNewRow} style={{ marginBottom: 10 }}>
      Add New Year
    </Button>
  );

  return (
    <div>
      <Row>
        <Col>{showAddButton ? <AddButton /> : null}</Col>
      </Row>
      <Table size="sm" bordered striped hover responsive {...getTableProps()} style={{}}>
        <thead className={styles.tableHeader}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                return (
                  <th className={styles.th} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} style={{}}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SchoolYearSettingsTable;

import { createContext } from 'react';
import { UserProfile } from '../users/userprofile';

export const UserContext = createContext<UserProfile>({
  user: {
    id: '0',
    tenantMembership: [],
    cognitoIds: [],
    familyName: '',
    givenName: '',
    email: '',
    phone: '',
    status: 'ACTIVE',
    thirdPartyAuth: true,
    clientId: '',
    associatedSchools: [],
    role: '',
    districtId: '',
    username: '',
    token: '',
    scope: {
      name: 'ALL',
      value: '',
    },
  },
  permissions: {},
  associatedAccounts: [], // structure is { "${tenantid}": {"${permissionId}": {create: true, update: true, read: false, delete: true}}}
});

import React, { useEffect } from 'react';
import { checkExternalUser } from '../api/users';
import { Loading } from '../Loading';
import { useParams } from 'react-router';

interface ExternalParams {
  token: string;
}

interface ExternalProps {
  handleRedirect: (url: string) => void;
}

const ExternalLogin = ({ handleRedirect }: ExternalProps) => {
  const { token } = useParams<ExternalParams>();

  useEffect(() => {
    const verifyUser = async () => {
      checkExternalUser(token).then(() => {
        sessionStorage.setItem('externalUser', 'true');
        handleRedirect('insights');
      });
    };
    verifyUser();
  }, [token]);

  return (
    <div>
      <Loading />
    </div>
  );
};

export default ExternalLogin;

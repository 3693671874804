import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTable, useSortBy, usePagination, useAsyncDebounce } from 'react-table';
import { Card, Table } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { useHistory } from 'react-router-dom';
import { TransferRequest } from '../../types/transferrequests';
import { Bars } from 'react-loader-spinner';

interface RequestTableProps {
  columns: [];
  loadingData: boolean;
  requests: TransferRequest[];
  onSort: (value: any) => void;
}

const RequestTable = ({ columns, requests, onSort, loadingData }: RequestTableProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable({ columns, data: requests, initialState: { pageIndex: 0 }, manualSortBy: true }, useSortBy, usePagination);

  const onRowClick = (cell: any) => {
    if (cell.column.Header === 'Actions') {
      history.push(`/roledetail/${cell.row.original.id}`);
    }
  };

  const onFetchDataDebounced = useAsyncDebounce(onSort, 100);

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  useEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters });
    setLoading(loadingData);
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, filters, loadingData]);

  return (
    <div className={styles.tableContainer}>
      <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
        <Table scrollX striped bordered hover size="sm" id="auditTable" responsive style={{ backgroundColor: 'white' }} {...getTableProps()}>
          <thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.thRequests}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading && (
              <tr>
                <td colSpan={columns.length}>
                  <Bars height="36" color="#2582b5" wrapperStyle={{ justifyContent: 'center' }} />
                </td>
              </tr>
            )}
            {!loading &&
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === 'Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            style={{ paddingLeft: '1.25em' }}
                            onClick={() => {
                              onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      } else if (cell.column.Header === 'Transfer Date') {
                        moment(cell.value).format('MM/DD/YYYY');
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            onClick={() => {
                              onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {moment(cell.value).format('MM/DD/YYYY')}
                          </td>
                        );
                      } else if (cell.column.Header === 'Request Date') {
                        moment(cell.value).format('MM/DD/YYYY');
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            onClick={() => {
                              onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {moment(cell.value).format('MM/DD/YYYY')}
                          </td>
                        );
                      } else if (cell.column.Header === 'Actions') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
                            onClick={() => {
                              onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            onClick={() => {
                              onRowClick(cell);
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default RequestTable;

import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../../styles/archiveui.module.css';
import { Link } from 'react-router-dom';
import UserInviteModal from '../userinvite/userinvitemodal';
import { getAccount } from '../../../api/accounts';
import { createUsersForTenant, inviteScribblesAdmin } from '../../../api/users';
import { toast } from 'react-toastify';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
  tenantId: string;
}

const SearchBar = ({ updateSearchText, searchText, tenantId }: searchBarProps): JSX.Element => {
  const [tenantName, setTenantName] = useState('');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const clearFilters = () => {
    updateSearchText('');
  };

  useEffect(() => {
    const getCurrentAccount = async () => {
      const currentAccount = await getAccount(tenantId);
      setTenantName(currentAccount.name);
    };
    getCurrentAccount();
  });

  const inviteUser = async (email: string, roleId: string) => {
    await toast.promise(createUsersForTenant(tenantId, { email: email }, [roleId]), {
      pending: 'Inviting User',
      success: 'User Invited',
      error: 'We were unable to invite this user',
    });
    toggle();
  };

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 className={styles.searchBarTitle}>Users for {tenantName}</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <Col>
                <FormGroup>
                  <Input type="text" id="search" placeholder="Search Name, ID" value={searchText} onChange={(e) => updateSearchText(e.target.value)} />
                </FormGroup>
              </Col>

              <Col md=".1">
                <Button id="clearFilterBtn" className={styles.filterBtn} onClick={clearFilters}>
                  <MdClear />
                </Button>
                <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
                <Button id="scrbBtnBlueMargin" className={styles.scrbBtnOrange} onClick={toggle}>
                  Invite User
                </Button>
                <UncontrolledTooltip target="scrbBtnBlueMargin">Invite User</UncontrolledTooltip>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <UserInviteModal toggle={toggle} modal={modal} tenantid={tenantId} inviteUser={inviteUser} />
    </div>
  );
};

export default SearchBar;

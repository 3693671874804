import React, { useEffect, useState } from 'react';
import UserTable from '../userlisttable/usertable';
import { getAccountUsers } from '../../../api/accounts';
import { useParams } from 'react-router';
import { Loading } from '../../../Loading';
import { User } from '../../../types/user';

export type Status = 'PENDING_INVITE' | 'ACTIVE' | 'INACTIVE';

export interface TenantMembership {
  tenantid: string;
  roles: string[];
}

export interface UserPartial {
  id: string;
  tenantMembership?: TenantMembership[];
  cognitoIds?: string[];
  familyName?: string;
  givenName?: string;
  email?: string;
  phone?: string;
  status?: Status;
}

interface UserListParams {
  tenantId: string;
}

const UserList = () => {
  const [users, updateUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { tenantId } = useParams<UserListParams>();
  const usersPerPage = 26;

  const setUsers = async () => {
    const users = await getAccountUsers(tenantId);
    updateUsers(users);
    setLoading(false);
  };

  useEffect(() => {
    setUsers();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstAccount = indexOfLastUser - usersPerPage;
  const currentAccounts = users.slice(indexOfFirstAccount, indexOfLastUser);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {loading && <Loading />}
      {!loading && <UserTable users={currentAccounts} tenantid={tenantId} />}
    </div>
  );
};

export default UserList;

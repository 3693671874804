import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Switch, useHistory, Route } from 'react-router-dom';
import styles from '../styles/archiveui.module.css';
import RequestsList from '../requests/requestlist';
import Config from '../config';
import RoleList from '../roles/rolelist';
import RoleDetail from '../roles/roledetail';
import UserList from '../users/userlist';
import UserDetail from '../users/userdetail/index';
import UserProfilePage, { UserProfile } from '../users/userprofile';
import FailedLogin from '../auth/failedlogin';
import { getUserProfile } from '../api/userprofile';
import { getDistrictConfigSettings, getUserConfigSettings } from '../api/configsettings';
import { PrivateRoute } from '../auth/privateroute';
import { AuthProvider } from '../auth/authprovider';
import AuthService from '../auth/authservice';
import Navigation from './Navbar';
import UserImport from '../users/userimport/importlist';
import { UserContext } from '../contexts/UserContext';
import { Loading } from '../Loading';
import SideNav from './SideNav';
import NotAuthorized from '../users/userprofile/notauthorized';
import AccountList from '../account/accountlist';
import AccountDetail from '../account/accountdetail';
import AuditLog from '../audit/auditlog';
import InternalUsersList from '../account/users/userslist/index';
import InternalUserDetail from '../account/users/userdetail/index';
import { Account } from '../types/account';
import ErrorBoundary from '../errorboundary';
import Toast from '../toast';
import Insights from '../insights';
import { DistrictConfigSettings, UserConfigSettings } from '../types/configsettings';
import ExternalLogin from '../auth/external';
import RequestorsList from '../requestors';

const Main = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const [externalUser, setExternalUser] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [currentTenants, setCurrentTenants] = useState<Account[]>([
    {
      tenantId: '0',
      shortName: 'default',
      name: 'default',
      description: 'defaultDesc',
      created: new Date(),
      status: 'active',
      tags: ['deafult'],
      contacts: [],
    },
  ]);

  const [userProfile, setUser] = useState<UserProfile>({
    user: {
      id: '0',
      tenantMembership: [],
      cognitoIds: [],
      familyName: '',
      givenName: '',
      email: '',
      phone: '',
      status: 'ACTIVE',
      thirdPartyAuth: true,
      clientId: '',
      associatedSchools: [],
      role: '',
      districtId: '',
      username: '',
      token: '',
      scope: {
        name: 'ALL',
        value: '',
      },
    },
    permissions: {},
    associatedAccounts: [], // structure is { "${tenantid}": {"${permissionId}": {create: true, update: true, read: false, delete: true}}}
  });

  const providerValue = useMemo(() => ({ userProfile, setUser }), [userProfile, setUser]);

  const { user } = useContext(UserContext);
  const [userConfigSettings, setUserConfigSettings] = useState<UserConfigSettings>({
    userId: user.id,
    displaySettings: [
      {
        displaySetting: 'Total Users',
        show: true,
      },
      {
        displaySetting: 'Enrollments',
        show: true,
      },
      {
        displaySetting: 'Transfer Requests Outbound Status',
        show: true,
      },
      {
        displaySetting: 'Transfer Requests Outbound Location',
        show: true,
      },
      {
        displaySetting: 'Transfer Requests Outbound Type',
        show: true,
      },
      {
        displaySetting: 'Graduation Rates',
        show: true,
      },
    ],
    enrollmentSettings: [
      { enrollmentSetting: 'Enrollment Setting 1', show: true },
      { enrollmentSetting: 'Enrollment Setting 2', show: true },
      { enrollmentSetting: 'Enrollment Setting 3', show: true },
    ],
  });
  const [districtSettings, setDistrictConfigSettings] = useState<DistrictConfigSettings>({
    districtId: user.districtId,
    schoolYearsSettings: [],
  });

  console.log(user);

  useEffect(() => {
    const setTenants = async () => {
      const userProfileFromAPI = await getUserProfile();
      const setUserConfig = await getUserConfigSettings(userProfileFromAPI.user.id);
      if (Object.keys(setUserConfig).length != 0) {
        setUserConfigSettings(setUserConfig);
      }
      const districtConfig = await getDistrictConfigSettings(userProfileFromAPI.user.districtId);
      if (Object.keys(districtConfig).length != 0) {
        setDistrictConfigSettings(districtConfig);
      }
      setUser(userProfileFromAPI);
      setLoading(false);
    };

    if (!history.location.pathname.includes('external')) {
      setTenants();
    }
  }, []);

  const logout = async () => {
    const auth = await AuthService.getInstance();
    await auth.logout();
  };

  const redirect = (url: string) => {
    history.push(`/${url}`);
    setExternalUser(true);
  };

  const mainContent = (
    <div>
      <UserContext.Provider value={providerValue.userProfile}>
        <Navigation isOpen={sidebarIsOpen} toggle={toggleSidebar} />
        <div className={styles.mainWrapper}>
          <SideNav sidebarIsOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} logout={logout} currentUsername={user.username} />
          <div className={styles.mainContainer}>
            <div className={styles.mainContainerBody}>
              <Switch>
                <ErrorBoundary>
                  <PrivateRoute path="/" exact component={(props) => <RequestsList {...props} />} />
                  <PrivateRoute path="/roles" exact component={(props) => <RoleList {...props} />} />
                  <PrivateRoute path="/roledetail/:id?" exact component={(props) => <RoleDetail {...props} />} />
                  <PrivateRoute path="/users" exact component={(props) => <UserList {...props} />} />
                  <PrivateRoute path="/importusers" exact component={(props) => <UserImport {...props} />} />
                  <PrivateRoute path="/userdetail/:id?" component={(props) => <UserDetail {...props} />} />
                  <PrivateRoute path="/userprofile" component={UserProfilePage} />

                  <PrivateRoute
                    path="/insights"
                    component={(props) => <Insights userConfigSettings={userConfigSettings} districtConfigSettings={districtSettings} {...props} />}
                  />
                  <PrivateRoute path="/requests" component={(props) => <RequestsList {...props} />} />
                  <PrivateRoute path="/requestors" component={(props) => <RequestorsList {...props} />} />
                  <PrivateRoute
                    path="/config"
                    component={(props) => <Config userConfigSettings={userConfigSettings} districtConfigSettings={districtSettings} {...props} />}
                  />

                  <PrivateRoute path="/notauthorized" component={NotAuthorized} />
                  <Route path="/failedlogin" component={() => <FailedLogin message="You must be invited to join the Archive" />} />

                  <Route path="/accountlist" exact component={AccountList} />
                  <Route path="/accountdetail/:tenantId?" component={AccountDetail} />
                  <Route path="/auditlog" exact component={AuditLog} />
                  <Route path="/accountusers/:tenantId?" component={InternalUsersList} />
                  <Route path="/accountuserdetail/:tenantId/:id?" component={InternalUserDetail} />
                </ErrorBoundary>
              </Switch>
            </div>
            {/* {!location.pathname.includes('viewer') && (
              <footer className={styles.footer}>
                <div
                  className="row alert alert-secondary"
                  style={{
                    marginTop: '20px',
                    maxWidth: '450px',
                    margin: '0px auto',
                    backgroundColor: 'white',
                    boxShadow:
                      '0 0.46875rem 2.1875rem rgb(59 62 102 / 3%), 0 0.9375rem 1.40625rem rgb(59 62 102 / 3%), 0 0.25rem 0.53125rem rgb(59 62 102 / 5%), 0 0.125rem 0.1875rem rgb(59 62 102 / 3%)',
                  }}
                >
                  <div className="col-sm text-center">
                    <a href="https://scribsoft.com/customer-responsibilities.html">Terms of Use</a>
                  </div>
                  <div className="col-sm text-center">
                    <a href="mailto:support@scribsoft.com">Support</a>
                  </div>
                  <div className="col-sm text-center">
                    <a href="https://scribsoft.com/privacy-policy.html">Privacy Policy</a>
                  </div>
                </div>

                <p style={{ marginTop: '30px' }} className="text-muted">
                  Archive Services brought to you by ScribSoft. <a href="https://www.scribsoft.com">Visit the homepage</a> or contact us{' '}
                  <a href="mailto:briefings@scribsoft.com">via email</a>.
                </p>
              </footer>
            )} */}
          </div>
        </div>
      </UserContext.Provider>
    </div>
  );
  const [authService, setAuthService] = useState<AuthService>();
  useEffect(() => {
    const getAuthService = async () => {
      console.log('HERE');
      const service = await AuthService.getInstance();
      setAuthService(service);
      if (externalUser) {
        setLoading(false);
      }
    };
    if (!history.location.pathname.includes('external')) {
      getAuthService();
    }
  }, [externalUser]);
  if (authService) {
    return (
      <AuthProvider authProvider={authService}>
        {!loading ? (
          <div>
            {mainContent}
            <Toast />
          </div>
        ) : (
          <Loading />
        )}
      </AuthProvider>
    );
  } else if (history.location.pathname.includes('external')) {
    return (
      <Switch>
        <Route path="/external/:token" component={() => <ExternalLogin handleRedirect={redirect} />} />
      </Switch>
    );
  } else {
    return <span>loading...</span>;
  }
};

export default Main;
